/* You can add global styles to this file, and also import other style files */
@use '@angular/material'as mat;

@import "../src/assets/themes/variables.scss";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// $accent: #092541;
// $selected-bg:rgba(170, 212, 152, 0.25);

//@import "~@progress/kendo-theme-default/scss/all";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$my-procurant-primary: mat.define-palette(mat.$indigo-palette);
$my-procurant-accent : mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$my-procurant-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$my-procurant-theme: mat.define-light-theme($my-procurant-primary, $my-procurant-accent, $my-procurant-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($my-procurant-theme);

@import "~ng-pick-datetime/assets/style/picker.min.css";

// @font-face{
//   font-family: "NunitoSans-Regular";
//   src: url("../src/assets/fonts/NunitoSans-Regular.ttf") format("ttf"),
// }

// @font-face{
//   font-family: "NunitoSans-ExtraLight";
//   src: url("../src/assets/fonts/NunitoSans-ExtraLight.ttf") format("ttf"),
// }

// @font-face{
//   font-family: "NunitoSans-Bold";
//   src: url("../src/assets/fonts/NunitoSans-Bold.ttf") format("ttf"),
// }

// @font-face{
//   font-family: "SegoeUI-Bold";
//   src: url("../src/assets/fonts/SegoeUI-Bold.ttf") format("ttf"),
// }

// @font-face{
//   font-family: "SegoeUI-Light";
//   src: url("../src/assets/fonts/SegoeUI-Light.ttf") format("ttf"),
// }

// @font-face{
//   font-family: "SegoeUI-Regular";
//   src: url("../src/assets/fonts/SegoeUI-Regular.ttf") format("ttf"),
// }

@font-face {
  font-family: "icomoon";
  src        : url("../src/assets/images/image-fonts/icomoon.eot?2mi4ol");
  src        : url("../src/assets/images/image-fonts/icomoon.eot?2mi4ol#iefix") format("embedded-opentype"),
    url("../src/assets/images/image-fonts/icomoon.ttf?2mi4ol") format("truetype"),
    url("../src/assets/images/image-fonts/icomoon.woff?2mi4ol") format("woff"),
    url("../src/assets/images/image-fonts/icomoon.svg?2mi4ol#icomoon") format("svg");
  font-weight: normal;
  font-style : normal;
}

/* kendo */
$accent     : #092541;
$selected-bg: #d8f0cd;
//@import "~@progress/kendo-theme-default/dist/all";
//@import "~@progress/kendo-theme-default/scss/grid";

@font-face {
  font-family: "sf_ui_displaysemibold";
  src        : url("../src/assets/fonts/sf-ui-display-semibold-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/sf-ui-display-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style : normal;
}

@font-face {
  font-family: "sf_ui_displayregular";
  src        : url("../src/assets/fonts/sf-ui-display-regular-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/sf-ui-display-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style : normal;
}

@font-face {
  font-family: "sf_ui_displaymedium";
  src        : url("../src/assets/fonts/sf-ui-display-medium-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/sf-ui-display-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style : normal;
}

@font-face {
  font-family: "sf_ui_displaybold";
  src        : url("../src/assets/fonts/sf-ui-display-bold-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/sf-ui-display-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style : normal;
}

/*Icons for site*/
@font-face {
  font-family: "icomoon";
  src        : url("../src/assets/images/image-fonts/icomoon.eot?2mi4ol");
  src        : url("../src/assets/images/image-fonts/icomoon.eot?2mi4ol#iefix") format("embedded-opentype"),
    url("../src/assets/images/image-fonts/icomoon.ttf?2mi4ol") format("truetype"),
    url("../src/assets/images/image-fonts/icomoon.woff?2mi4ol") format("woff"),
    url("../src/assets/images/image-fonts/icomoon.svg?2mi4ol#icomoon") format("svg");
  font-weight: normal;
  font-style : normal;
}

@font-face {
  font-family: "procurant";
  src        : url("assets/fonts/procurant.eot?46989818");
  src        : url("assets/fonts/procurant.eot?46989818#iefix") format("embedded-opentype"),
    url("assets/fonts/procurant.woff2?46989818") format("woff2"),
    url("assets/fonts/procurant.woff?46989818") format("woff"),
    url("assets/fonts/procurant.ttf?46989818") format("truetype"),
    url("assets/fonts/procurant.svg?46989818#procurant") format("svg");
  font-weight: normal;
  font-style : normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family   : "icomoon" !important;
  speak         : none;
  font-style    : normal;
  font-weight   : normal;
  font-variant  : normal;
  text-transform: none;
  line-height   : 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*Icons 2*/
@font-face {
  font-family: "icomoon2";
  src        : url("../src/assets/images/image-fonts/icomoon2.eot?9nv9wg");
  src        : url("../src/assets/images/image-fonts/icomoon2.eot?9nv9wg#iefix") format("embedded-opentype"),
    url("../src/assets/images/image-fonts/icomoon2.ttf?9nv9wg") format("truetype"),
    url("../src/assets/images/image-fonts/icomoon2.woff?9nv9wg") format("woff"),
    url("../src/assets/images/image-fonts/icomoon2.svg?9nv9wg#icomoon2") format("svg");
  font-weight: normal;
  font-style : normal;
}

* {
  font-family: "Nunito Sans";
}

[class^="icon2-"],
[class*=" icon2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family   : "icomoon2" !important;
  speak         : none;
  font-style    : normal;
  font-weight   : normal;
  font-variant  : normal;
  text-transform: none;
  line-height   : 1;
  padding-right : 2px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-upload_file_grey:before {
  content: "\e91d";
  color  : #4a4a4a;
}

.icon2-drag:before {
  content: "\e9114";
  color  : #739d44;
}

.icon2-view-details:before {
  content: "\e9115";
  color  : #739d44;
}

.icon-claim:before {
  content: "\e909";
  color  : #fff;
}

.icon-claim_green:before {
  content: "\e909";
  color  : #739d44;
}

.icon2-business-rules:before {
  content: "\e90b";
  color  : #fff;
}

.icon2-trading-partners:before {
  content: "\e90c";
  color  : #fff;
}

.icon2-business-unit:before {
  content: "\e90d";
  color  : #fff;
}

.icon2-invoice:before {
  content: "\e90e";
  color  : #fff;
}

.icon2-invoice_green:before {
  content: "\e90e";
  color  : #739d44;
}

.icon2-info:before {
  content      : "\e90a";
  color        : #739d44;
  border       : 1px solid #739d44;
  border-radius: 100%;
  padding      : 2px;
}

.icon2-no-more-orders:before {
  content: "\e906";
  color  : #e7e6e6;
}

.icon2-xls:before {
  content: "\e907";
  color  : #9b9b9b;
}

.icon2-truck:before {
  content: "\e908";
  color  : #4a4a4a;
}

.icon2-truck1:before {
  content: "\e908";
  color  : #739d44;
}

.icon2-like:before {
  content: "\e909";
  color  : #4a4a4a;
}

.icon2-save-draft:before {
  content: "\e905";
  color  : #9b9b9b;
}

.icon2-send:before {
  content    : "\e904";
  color      : #fff;
  font-weight: 600;
}

.icon2-assign-to-multiple:before {
  content: "\e900";
  color  : #9b9b9b;
}

.icon2-list:before {
  content: "\e901";
  color  : #fff;
}

.icon2-settings:before {
  content: "\e902";
  color  : #fff;
}

.icon2-three-dot:before {
  content: "\e903";
  color  : #739d44;
}

.icon-edit:before {
  content    : "\e932";
  color      : #739d44;
  font-weight: 600;
}

.icon-time:before {
  content    : "\e92f";
  color      : #739d44;
  font-weight: 600;
}

.icon-discard:before {
  content: "\e930";
  color  : #739d44;
}

.icon-filter:before {
  content    : "\e931";
  color      : #739d44;
  font-weight: 600;
}

.icon-terms:before {
  content: "\e900";
  color  : #9b9b9b;
}

.icon-clear-filter:before {
  content    : "\e933";
  color      : #739d44;
  font-weight: 600;
}

.icon-other:before {
  content: "\e901";
  color  : #9b9b9b;
}

.icon-cancel:before {
  content      : "\e902";
  color        : #739d44;
  border       : 1px solid #739d44;
  border-radius: 100%;
}

.icon-attach:before {
  content: "\e903";
  color  : #739d44;
}

.icon-account_settings:before {
  content: "\e904";
  color  : #fff;
}

.icon-analytics:before {
  content: "\e905";
  color  : #fff;
}

.icon-catalog:before {
  content: "\e906";
  color  : #fff;
}

.icon-dashboard:before {
  content: "\e907";
  color  : #fff;
}

.icon-orders_list:before {
  content: "\e908";
  color  : #fff;
}

.icon-payments:before {
  content: "\e909";
  color  : #fff;
}

.icon-pricing:before {
  content: "\e90a";
  color  : #fff;
}

.icon-promotions:before {
  content: "\e90b";
  color  : #fff;
}

.icon-task_centre:before {
  content: "\e90c";
  color  : #fff;
}

.icon-demand:before {
  content: "\e90d";
  color  : #fff;
}

.icon-inventory:before {
  content: "\e90e";
  color  : #fff;
}

.icon-logistics:before {
  content: "\e90f";
  color  : #fff;
}

.icon-source:before {
  content: "\e910";
  color  : #fff;
}

.icon-tasks:before {
  content: "\e911";
  color  : #fff;
}

.icon-empty_star:before {
  content: "\e912";
  color  : #fff;
}

.icon-filled_star:before {
  content: "\e913";
  color  : #e5c60b;
}

.icon-half_filled_star:before {
  content: "\e914";
}

.icon-tools:before {
  content: "\e915";
  color  : #739d44;
}

.icon-catalog2:before {
  content: "\e916";
  color  : #4a4a4a;
}

.icon-featured_suppliers:before {
  content: "\e917";
}

.icon-location:before {
  content: "\e918";
  color  : #43a40f;
}

.icon-right_arrow:before {
  content: "\e919";
  color  : #739d44;
}

.icon-today_deal:before {
  content: "\e91a";
  color  : #4a4a4a;
}

.icon-image_upload:before {
  content: "\e91c";
  color  : #739d44;
}

.icon-upload_file:before {
  content: "\e91d";
  color  : #4a4a4a;
}

.icon-search:before {
  content: "\e91e";
  color  : #739d44;
}

.icon-history:before {
  content: "\e91f";
  color  : #739d44;
}

.icon-other1:before {
  content: "\e920";
  color  : #838383;
}

.icon-payments2:before {
  content: "\e921";
  color  : #838383;
}

.icon-transportation:before {
  content: "\e922";
  color  : #838383;
}

.icon-comment_green:before {
  content: "\e923";
  color  : #739d44;
}

.icon-wishlist:before {
  content: "\e924";
  color  : #739d44;
}

.icon-wishlist-grey:before {
  content: "\e924";
  color  : grey;
}

.icon-calender:before {
  content: "\e925";
  color  : #739d44;
}

.icon-delete:before {
  content: "\e926";
  color  : #739d44;
}

.icon-reminders:before {
  content: "\e927";
  color  : #739d44;
}

.icon-chat_message:before {
  content: "\e928";
  color  : #2a3037;
}

.icon-chat:before {
  content: "\e929";
  color  : #4a4a4a;
}

.icon-flag:before {
  content: "\e92a";
  color  : gainsboro;
}

.icon-flag-active:before {
  content: "\e92a";
  color  : #d0021b;
}

.icon-messages:before {
  content: "\e92b";
}

.icon-recent_activity:before {
  content: "\e92c";
}

.icon-reminder_notification:before {
  content: "\e92d";
  color  : #2a3037;
}

.icon-system_generated_message:before {
  content: "\e92e";
  color  : #858585;
}

.icon-trending_product:before {
  content: "\e91b";
}

.icon-block:before {
  content: "\e934";
}

.icon-exclamation-outline:before {
  content: "\e935";
}

.icon-exclamation-solid:before {
  content: "\e936";
}

.icon-minus-outline:before {
  content: "\e937";
}

.icon-minus-solid:before {
  content: "\e938";
}

.icon-warning:before {
  content: "\ea07";
}

.icon-list:before {
  content: "\e93f";
}

.icon-refresh:before {
  content: "\e940";
}

.icon2-user:before {
  content: "\e90f1";
  color  : #9b9b9b;
}

.icon2-mobile:before {
  content: "\e9101";
  color  : #9b9b9b;
}

.icon2-route:before {
  content: "\e9111";
  color  : #9b9b9b;
}

.icon2-route_green:before {
  content: "\e9111";
  color  : #739d44;
}

.icon-tab_indicator:before {
  content    : "\ea45";
  color      : #f57f23;
  font-weight: 600;
}

.icon-tab:before {
  content    : "\ea45";
  color      : #739d44;
  font-weight: 600;
}

.icon-bold:before {
  content    : "\e914";
  color      : #739d44;
  font-weight: 600;
}

.icon-plus:before {
  content    : "\e91b";
  color      : #739d44;
  font-weight: 600;
}

.icons-download:before {
  content: "\e812";
}

.icon-seen-eye:before {
  content    : "\e93a";
  color      : #739d44;
  font-weight: bold;
}

.icon-seen-eye-grey:before {
  content: "\e93a";
  color  : #9b9b9b;
}

.icon-open-eye:before {
  content: "\e939";
}

.icon-eye-regular:before {
  content: "\e93b";
}

.icon-eye-slash-regular:before {
  content: "\e93c";
}

.icon-check-circle:before {
  content: "\f058";
  color  : #739d44;
}

.icon-check-circle-o:before {
  content: "\f05d";
  color  : #9b9b9b;
}

.icon-circle-up:before {
  content: "\ea41";
}

.icon-circle-right:before {
  content: "\ea42";
}

.icon-circle-down:before {
  content: "\ea43";
}

.icon-circle-left:before {
  content: "\ea44";
}

.icon-library_add:before {
  content: "\e93d";
}

.icon-track-trace:before {
  content: "\e93e";
}

// (RD)PMB-PMB-6364 Task Center Enhancements for better look and feel Start
.icon-notification:before {
  content: "\e942";
}

.icon-filter1:before {
  content: "\e943";
}

.icon-Keyboard-arrow-down:before {
  content: "\e944";
}

.icon-Create-Orders-Draft:before {
  content: "\e945";
}

.icon-Delayed-Loads:before {
  content: "\e946";
}

.icon-Draft-Loads:before {
  content: "\e947";
}

.icon-Freight-invoice:before {
  content: "\e948";
}

.icon-Modified-Load:before {
  content: "\e949";
}

.icon-New-Invoices:before {
  content: "\e94a";
}

.icon-Orders-Modified:before {
  content: "\e94b";
}

.icon-Receipt-Exception:before {
  content: "\e94c";
}

.icon-Unassigned-Orders:before {
  content: "\e94d";
}

.icon-Watchlist:before {
  content: "\e94e";
}

.icon-Auto-Orders:before {
  content: "\e94f";
}

// (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce
.icon-Dashbaord:before {
  content: "\e950";
}

.icon-Group:before {
  content: "\e951";
  color  : #fff;
}

//(RD)PMB-PMB-6364 Task Center Enhancements for better look and feel End

/*removing default padding and margin*/
html {
  margin : 0;
  padding: 0;
  height : 100%;

  body {
    margin     : 0;
    padding    : 0;
    font-family: "sf_ui_displayregular";
    //height: 100%;
  }

  .content-container {
    .main-content {
      padding: 5px 15px 15px 15px;
    }

    .header-tab-bar {
      padding: 15px;
    }
  }
}

// SVG font icons starts here

[class^="icons-"]:before,
[class*=" icons-"]:before {
  font-family            : "procurant";
  font-style             : normal;
  font-weight            : normal;
  speak                  : none;
  display                : inline-block;
  text-decoration        : inherit;
  width                  : 1em;
  text-align             : center;
  font-variant           : normal;
  text-transform         : none;
  line-height            : 1em;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Side navigation */
// .sidenav {
//   > .mat-drawer-inner-container {
//     flex-direction: column;
//     box-sizing: border-box;
//     display: flex;
//     place-content: stretch space-between;
//     align-items: stretch;
//   }
// }
// .mat-nav-list {
//   .mat-list-item {
//     height: 60px !important;
//     border-bottom: 1px solid rgba(255, 255, 255, 0.15);
//     text-align: center;
//     .mat-list-item-content {
//       padding: 0 23px !important;

//       & > span {
//         font-size: 20px;
//         opacity: 0.5;
//       }
//     }
//   }
// }
.bottomList {
  .mat-list-item {
    &:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

/* Right side container */
.contentWrapper {
  padding: 65px 0 40px 66px;
}

.contentWrapper.contentWrapperColor {
  background-color: #f9f9f9;
}

.contentLoginWrapper {
  display        : flex;
  justify-content: center;
  align-items    : center;
  height         : 100vh;
}

.mat-drawer-container {
  background: #fff;
  overflow  : inherit !important;
}

/*Table */

.table {
  width          : 100%;
  border-collapse: collapse;

  td {
    border : 1px solid #e3e2e2;
    padding: 8px 10px !important;

    &.pd-0 {
      padding: 0 !important;
    }

    .tableSecondLevel {
      td {
        border: 0;

        &:last-child {
          border: 0;
        }

        &.tdBorderRight {
          border-right: 1px solid #e3e2e2 !important;
        }
      }
    }

    .fieldLabel,
    .fieldValue {
      //color: #4b4b4b;
      color: #717171;
    }

    .ImgWrap {
      width : 80px;
      height: 80px;
      margin: auto;

      img {
        width  : 100%;
        margin : auto;
        display: block;
      }
    }

    mat-form-field {
      width: 100%;

      .mat-form-field-underline {
        height: 0;
      }
    }

    .mat-form-field-wrapper {
      padding: 0;

      .mat-form-field-infix {
        border : 0;
        padding: 0;
      }
    }

    .mat-form-field-label-wrapper {
      padding: 0;
      top    : 0;
    }

    .mat-form-field-underline {
      display: none;
    }

    &.mat-column-expandedDetail {
      padding : 0 !important;
      overflow: hidden;
    }

    &.expandControl {
      position: relative;
      cursor  : pointer;

      &:before {
        content     : "";
        width       : 0;
        height      : 0;
        border-left : 6px solid transparent;
        border-right: 6px solid transparent;
        border-top  : 8px solid #9b9b9b;
        position    : absolute;
        display     : block;
        right       : 0;
        top         : 50%;
        margin-top  : -4px;
        margin-right: 10px;
      }
    }

    .tdBg {
      background-color: #f9f9f9;
    }

    .tdBtn {
      padding    : 0;
      margin     : 0;
      width      : auto;
      height     : auto;
      line-height: normal;
      min-width  : auto;
      float      : right;
    }

    .infoBlocks .readOnlyField,
    .infoBlocks .formControl {
      padding-right: 0;
    }
  }

  .element-detail-row .infoBlocks {
    padding: 10px 10px;
  }

  .tdBg {
    background-color: #f9f9f9;
  }

  th {
    border-top   : 1px solid #739d44 !important;
    padding      : 8px 10px !important;
    border-bottom: 1px solid #739d44 !important;
    border-right : 1px solid #e3e2e2;
    border-left  : 1px solid #e3e2e2;

    // background-color: #f8faf6;
    &.pd-0 {
      padding: 0 !important;
    }

    .tableSecondLevel {
      th {
        border       : 0;
        border-top   : 1px solid #e3e2e2;
        border-right : 1px solid #e3e2e2;
        border-bottom: 0 !important;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .thBtn {
      padding    : 0;
      margin     : 0;
      width      : auto;
      height     : auto;
      line-height: normal;
      min-width  : auto;
      float      : right;
    }
  }

  tr.mat-header-row,
  tr.mat-row {
    height: auto !important;
  }

  td.updatedTd {
    &:before {
      border-top-color: #e9670e;
    }
  }

  td.updatedRow {
    &:first-child {
      border-left: 10px solid #e9670e;
    }

    .expandCta {
      span {
        &.collapsed:before {
          border-top-color: #e9670e !important;
        }

        &.expanded:before {
          border-bottom-color: #e9670e !important;
        }
      }
    }
  }

  td.fixedData {
    position: relative;
    left    : 0;
    top     : 0;

    >span {
      position      : absolute;
      vertical-align: middle;
      top           : 50%;
      left          : 10px;
      margin-top    : -15px;
      background    : rgba(0, 0, 0, 0.04);
      width         : 30px;
      height        : 30px;
      border-radius : 100%;
      text-align    : center;
      line-height   : 30px;

      &.auto-price {
        background: rgba(210, 204, 235, 1.04);
      }
    }

    >div {
      display        : flex;
      align-items    : center;
      justify-content: space-between;
      min-width      : 130px;
      width          : auto;

      >span {
        background   : rgba(0, 0, 0, 0.04);
        width        : 30px;
        height       : 30px;
        border-radius: 100%;
        text-align   : center;
        line-height  : 30px;
        display      : block;
      }

      >p {
        width        : 10px;
        height       : 10px;
        border-radius: 100%;
        text-align   : center;
        line-height  : 10px;
        display      : block;
        margin-left  : 10px;
      }
    }
  }

  .mat-checkbox-inner-container {
    width        : 20px;
    height       : 20px;
    border-radius: 3px;
    border       : 1px solid #9b9b9b;
  }

  .mat-checkbox-frame {
    border-width: 0px;
  }

  .mat-checkbox-checked .mat-checkbox-inner-container,
  .mat-checkbox-indeterminate .mat-checkbox-inner-container {
    border: 1px solid #fff;
  }

  tfoot {
    background: #f9f9f9;

    td {
      padding     : 10px 10px !important;
      border-left : 0;
      border-right: 0;
      border-top  : 0;
    }
  }

  .mat-footer-cell {
    font-size  : 18px;
    color      : #2a3037;
    font-family: "sf_ui_displaysemibold";
  }
}

.element-active-row {
  .expandControl {
    position: relative;
    cursor  : pointer;

    &:before {
      border-left  : 6px solid transparent;
      border-right : 6px solid transparent;
      border-bottom: 8px solid #9b9b9b;
      border-top   : 0 !important;
    }
  }
}

.element-detail-row {
  height: auto !important;

  .infoBlocks {
    padding: 20px;
  }
}

.responsiveTable {
  overflow-x: auto;
}

.mat-paginator-container {
  justify-content: center !important;
  box-shadow     : 0 1px 3px rgba(0, 0, 0, 0.04);
  border-radius  : 3px;
  border         : 1px solid rgba(0, 0, 0, 0.06);
  padding        : 0 15px !important;
  margin         : auto;
  width          : auto !important;
  min-height     : auto !important;

  .mat-paginator-icon {
    width: 22px;
  }

  .mat-paginator-page-size-select {
    margin: 6px 4px 0 4px;
    width : 50px;

    .mat-select-arrow:before {
      border-width: 6px;
    }

    .mat-select-arrow:after {
      border-width: 6px;
    }
  }
}

.mat-paginator {
  margin-top: 40px;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background: #114267;
}

.mat-sort-header-button {
  text-align: left !important;
}

.mat-sort-header-arrow {
  color: #739d44;
}

.mat-cell,
.mat-footer-cell {
  color: #2a3037;
}

.mat-header-cell {
  color      : #4b4b4b;
  font-size  : 14px;
  font-family: "sf_ui_displaysemibold";
}

.mat-cell,
.mat-footer-cell {
  font-size: 16px;
}

.mat-elevation-z8 {
  box-shadow: none;
}

.activeRow {
  background-color: #f9f9f9;
}

.second-footer-row {
  border-top: 20px solid #fff;
}

.third-footer-row {
  background: #fff;

  td {
    border: 0 !important;

    span {
      color: #4a4a4a;
    }
  }
}

.grandTotal {
  &.tdHighlight {
    width           : auto;
    margin          : 20px 171px 0 46px;
    border-radius   : 6px;
    box-shadow      : inset 0 0 0px 1px #9aa8b5;
    // border: solid 1px #9aa8b5 !important;
    border          : 0 !important;
    background-color: #fff;
    padding         : 10px;

    .total {
      span {
        font-size  : 20px;
        color      : #2a3037;
        font-family: "sf_ui_displaysemibold";
      }
    }
  }
}

.tdHighlight {
  border-radius   : 6px;
  box-shadow      : inset 0 0 0px 1px #9aa8b5;
  // border: solid 1px #9aa8b5 !important;
  border          : 0 !important;
  background-color: #fff;

  span {
    font-size: 24px;
  }
}

.border_B {
  border-bottom : 1px solid #e9e9e9;
  margin-bottom : 10px;
  padding-bottom: 10px;
}

.tdHighlight2 {
  background-color: #f9f9f9;
  box-shadow      : inset 0px 20px 0px 0px #fff;
}

.table tfoot td.tdHighlight2 {
  padding-top: 40px !important;
}

.cdk-column-blanks {
  background: #fff;
  border    : 0 !important;
}

.tableFilterPanel {
  padding      : 20px;
  padding-left : 0px;
  padding-right: 0px;

  .dateRange {
    .mat-form-field-infix {
      width: 155px;
    }
  }
}

.table .mat-column-select {
  // padding-left: 20px;
  // padding-right: 20px;
  width: 20px;
}

.mat-table,
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-family: "sf_ui_displayregular";
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "sf_ui_displaymedium";
}

.mat-dialog-content {

  table tr td,
  table tr th {
    &:first-child {
      border-left: 0 !important;
    }

    &:last-child {
      border-right: 0 !important;
    }
  }
}

/* Form */
.formContainer {
  mat-form-field {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.space40 {
    mat-form-field {
      margin-right: 40px;
    }
  }

  .dependentField:last-child {
    mat-form-field {
      margin-right: 0 !important;
    }
  }

  .mat-checkbox-label {
    font-size   : 12px;
    line-height : normal;
    color       : #4a4a4a;
    font-family : "sf_ui_displayregular" !important;
    padding-left: 10px;
  }

  &.fullFields {
    mat-form-field {
      width       : 100%;
      margin-right: 0;
    }
  }

  &.halfFields {
    mat-form-field {
      width       : 50%;
      display     : inline-block;
      margin-right: 0;

      .mat-form-field-wrapper {
        margin-right: 15px;
      }
    }
  }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #c7d1dc;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #739d44;
}

.mat-form-field-label {
  color    : #c7d1dc;
  font-size: 18px;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background: #114267;
}

.mat-sort-header-arrow {
  color: #739d44;
}

.mat-form-field-label,
.mat-form-field-appearance-legacy .mat-form-field-label {
  color        : #212121;
  //font-weight: 300;
}

.mat-form-field-should-float .mat-form-field-label {
  color: #212121;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #212121;
}

.mat-calendar-body-selected,
.mat-form-field.mat-focused .mat-form-field-ripple {
  background: #739d44;
}

.mat-option {
  font-size: 14px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  // color: #739d44;
  //(RD)PMB-7119 Order Details
  background    : #EAF5DC !important;
  color         : #212121;
  font-size     : 14px;
  font-style    : normal;
  font-weight   : 600;
  line-height   : normal;
  letter-spacing: 0.5px;

  .mat-option-text {
    color         : #212121 !important;
    font-size     : 14px !important;
    font-style    : normal !important;
    font-weight   : 600 !important;
    line-height   : normal !important;
    letter-spacing: 0.5px;
  }
}

.mat-option:hover {
  background: #EAF5DC !important;
}

.readOnlyField {

  // span{
  //     position: relative;
  // }
  .mat-select-value,
  .mat-input-element {
    font-family: "sf_ui_displaybold";
  }

  .fieldLabel,
  .fieldValue {
    color: #2a3037;
  }

  .fieldLabel {
    margin-right: 5px;
    font-size   : 14px;
    font-family : "sf_ui_displayregular";
  }

  .fieldValue {
    font-size    : 14px;
    font-family  : "sf_ui_displaybold";
    width        : 100%;
    text-overflow: ellipsis;
    overflow     : hidden;
    display      : block;
    white-space  : nowrap;
  }

  .fieldGroup {
    clear        : both;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .fieldIcon {
    position   : absolute;
    margin-top : -3px;
    margin-left: 15px;
  }
}

.readOnlyField.blockFields {
  .fieldValue {
    font-size  : 14px;
    color      : #2a3037;
    font-family: "sf_ui_displaymedium";
  }

  .fieldLabel {
    display    : block;
    font-size  : 12px;
    color      : #8a9199;
    font-family: "sf_ui_displayregular";
  }

  .fieldGroup {
    clear        : both;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.certificates {
  width  : 80px;
  height : 40px;
  display: block;

  img {
    height: 100%;
  }
}

.mat-form-field,
.mat-select {
  font-family: "sf_ui_displayregular" !important;
}

.mat-select-panel {
  margin-top : 25px;
  margin-left: 8px;
}

/*Buttons*/
.popupLink {
  font-family: "sf_ui_displaysemibold";
  cursor     : pointer;
}

.greenColorIcon {
  color: #739d44;
}

.orangeColorIcon {
  color: #e9670e;
}

.greenColor {
  &.icon-other:before {
    color: #739d44;
  }

  &.icon2-send:before {
    color: #739d44;
  }

  &.icon-demand:before {
    color: #739d44;
  }
}

.greenColor {
  &.icon-other1:before {
    color: #739d44;
  }
}

.commentAlert {
  &:after {
    content         : "";
    width           : 9px;
    height          : 9px;
    background-color: #f57f23;
    position        : absolute;
    border-radius   : 50px;
    right           : 0;
    margin-top      : 3px;
    margin-right    : 4px;
    border          : 2px solid #fff;
  }
}

.availableAlert {
  &:after {
    content         : "";
    width           : 9px;
    height          : 9px;
    background-color: #2a8fcb;
    position        : absolute;
    border-radius   : 50px;
    right           : 0;
    margin-top      : 3px;
    margin-right    : 4px;
    border          : 2px solid #fff;
  }
}

.btnBasic {
  font-family: "sf_ui_displaybold";
  font-size  : 14px;
  color      : #4a4a4a;
  padding    : 0 !important;
  box-shadow : none !important;
  line-height: inherit !important;
  padding    : 10px 15px !important;
  background : transparent;
}

.btnLink {
  font-family    : "sf_ui_displaysemibold";
  font-size      : 14px;
  color          : #739d44;
  text-decoration: none;
}

.basicBtn {
  padding   : 0 !important;
  width     : auto;
  height    : auto;
  min-width : auto !important;
  background: none;
  border    : 0;
  box-shadow: none !important;
  cursor    : pointer;

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }

  &:first-child {
    margin-right: 0 !important;
  }

  [class^="icon2-"],
  [class*=" icon2-"],
  [class^="icon-"],
  [class*=" icon-"] {
    margin-right: 10px;
  }
}

.btn {
  margin-right: 25px !important;

  &:last-child {
    margin-right: 0 !important;
  }
}

.roundBtn {
  padding      : 0 !important;
  width        : 38px;
  height       : 38px;
  min-width    : 38px !important;
  border-radius: 50px !important;
}

.btnPrimary {
  border-radius   : 3px;
  background-color: #739d44;
  color           : #f2f9fc;
  font-family     : "sf_ui_displaysemibold";
  font-size       : 14px;
  border          : solid 1px #739d44 !important;
  padding         : 8px 15px !important;
  line-height     : normal !important;
  height          : auto;
  box-shadow      : none !important;
}

.btnSecondary {
  border-radius   : 3px !important;
  background-color: #ffffff;
  color           : #4a4a4a;
  font-family     : "sf_ui_displaysemibold";
  font-size       : 14px;
  border          : solid 1px #739d44 !important;
  padding         : 8px 15px !important;
  line-height     : normal !important;
  height          : auto;
  box-shadow      : none !important;

  span {
    margin-right: 10px;
  }

  span.mat-button-wrapper {
    margin-right: 0;
  }

  &.greenBtn {
    height : 40px;
    padding: 0 15px !important;

    .mat-button-wrapper {
      color: #739d44;
    }

    &.smallBtn {
      height : 30px;
      padding: 0 10px !important;
    }
  }

  a {
    color          : #4a4a4a;
    text-decoration: none;
  }
}

.btnTertiary {
  border-radius   : 3px !important;
  background-color: transparent;
  color           : #739d44;
  font-family     : "sf_ui_displaysemibold";
  font-size       : 14px;
  border          : none;
  padding         : 8px 15px !important;
  line-height     : normal !important;
  height          : auto;
  box-shadow      : none !important;
  min-width       : auto !important;

  .icon-right_arrow:before {
    margin-left: 5px;
    font-size  : 12px;
  }

  .mat-icon {
    margin-top: -10px;
  }
}

.btnDelete {
  border-radius   : 3px;
  background-color: #ececec;
  color           : red;
  font-family     : "sf_ui_displaysemibold";
  font-size       : 14px;
  font-weight     : 300;
  border          : solid 1px #ececec !important;
  padding         : 8px 15px !important;
  line-height     : normal !important;
  height          : auto;
  box-shadow      : none !important;
}

.linkBtn {
  border       : 0;
  border-radius: 0 !important;

  mat-button-toggle {
    margin-right: 30px;
    border-left : 0 !important;

    &.mat-button-toggle-checked,
    &:hover {
      background-color: transparent;
    }

    // &:last-child{
    //     margin: 0;
    // }
    &.mat-button-toggle-checked .mat-button-toggle-label-content,
    &.mat-button-toggle-checked .mat-button-toggle-label-content>span:before {
      color: #4a4a4a;
    }

    button {
      font-family: "sf_ui_displayregular";
      font-size  : 18px;
      color      : #739d44;

      .mat-button-toggle-label-content {
        padding    : 0;
        line-height: normal;
        transition : all 0.3s;

        >span:before {
          color: #739d44;
        }
      }
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
    background-color: transparent !important;
  }

  .mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: 0;
  }

  .mat-ripple {
    display: none;
  }
}

.filterLinkBtn {
  border       : 0;
  border-radius: 0 !important;

  mat-button-toggle {
    margin-right: 25px;
    border-left : 0 !important;

    &.mat-button-toggle-checked,
    &:hover {
      background-color: transparent;

      button {
        .mat-button-toggle-label-content {
          color: #739d44;
        }
      }
    }

    &:last-child {
      margin: 0;
    }

    // &.mat-button-toggle-checked .mat-button-toggle-label-content{
    //     box-shadow: inset 0px -4px 0px #092541;
    //     color: #4a4a4a;
    // }
    &.mat-button-toggle-checked .mat-button-toggle-label-content:after {
      content   : "";
      width     : 100%;
      height    : 2px;
      display   : block;
      background: #739d44;
      position  : absolute;
      bottom    : 0;
      left      : 0;
    }

    button {
      font-family: "sf_ui_displayregular";
      font-size  : 15px;
      color      : #4a4a4a;

      .mat-button-toggle-label-content {
        padding    : 0;
        line-height: normal;
        transition : all 0.3s;
        position   : relative;
      }
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
  .mat-button-toggle-appearance-standard {
    background-color: transparent !important;
  }

  .mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: 0;
  }

  .mat-ripple {
    display: none;
  }
}

.tableLinks {
  font-family    : "sf_ui_displayregular";
  color          : #739d44 !important;
  cursor         : pointer;
  text-decoration: underline !important;
}

button {
  font-family: "sf_ui_displayregular";
}

/*Table comment section */
.commentActionPanel {
  padding     : 10px 20px;
  box-shadow  : 0 1px 8px 0 rgba(0, 0, 0, 0.28);
  margin-top  : -35px;
  margin-right: 40px;

  .icon-comment_green:before {
    color: #c7d1dc;
  }

  .mat-menu-content:not(:empty) {
    padding: 0;
  }

  button.mat-menu-item {
    padding    : 0;
    margin     : 0;
    line-height: normal;
    height     : auto;
    font-size  : 12px;
    color      : #739d44;
    text-align : right;
    margin-top : 10px;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }

    .mat-menu-ripple {
      display: none !important;
    }
  }

  .commentText {
    color       : #4b4b4b;
    font-size   : 16px;
    margin-right: 12px;
  }
}

.loadActionPanel {
  padding     : 10px 20px;
  box-shadow  : 0 1px 8px 0 rgba(0, 0, 0, 0.28);
  margin-top  : 0px;
  margin-right: 0px;

  button.mat-menu-item {
    padding    : 0;
    margin     : 0;
    line-height: normal;
    height     : auto;
    font-size  : 12px;
    color      : #739d44;
    text-align : right;
    margin-top : 10px;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }

    .mat-menu-ripple {
      display: none !important;
    }
  }

  .commentText {
    color       : #4b4b4b;
    font-size   : 16px;
    margin-right: 12px;
  }
}

.tooltipIcon {
  color        : #739d44;
  width        : 15px;
  height       : 15px;
  border       : 2px solid #739d44;
  border-radius: 100%;
  text-align   : center;
  line-height  : 18px;
  font-size    : 10px;
  font-weight  : bold;
  display      : block;
  margin       : auto;
}

.mat-menu-panel.load-action-panel {
  min-width: 50px !important;

  .mat-menu-content {
    padding: 15px !important;
  }

  .load-item {
    color        : #4a4a4a;
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }

    span {
      &.value {
        font-family: "sf_ui_displaybold";
        color      : #4a4a4a;
        margin-left: 10px;
      }
    }
  }
}

.tooltipPanel {
  padding    : 10px 20px;
  box-shadow : 0 1px 8px 0 rgba(0, 0, 0, 0.28);
  margin-top : -40px;
  margin-left: -9px;

  .roadMap {
    min-width : 400px;
    width     : 100%;
    background: #e5e5e5;
    height    : 220px;
  }

  &.mat-menu-panel {
    max-width: 100%;
  }

  &.withBasicBtn {
    margin-top : -29px;
    margin-left: -20px;
  }

  .tooltipIcon {
    position: absolute;
  }

  .mat-menu-content:not(:empty) {
    padding: 0;
  }

  button.mat-menu-item {
    padding    : 0;
    margin     : 0;
    line-height: normal;
    height     : auto;
    font-size  : 12px;
    color      : #739d44;
    text-align : right;
    margin-top : 10px;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }

    .mat-menu-ripple {
      display: none !important;
    }
  }

  .tooltipText {
    .readOnlyField .fieldLabel {
      display: block;
    }
  }
}

/*Remove borders*/
.noBorders .mat-form-field-underline {
  display: none;
  border : 0;
}

/*Down arrow*/
.mat-select-arrow {
  position: relative;
  top     : -4px;
  border  : 0 !important;
}

.mat-select-arrow:after,
.mat-select-arrow:before {
  top           : 100%;
  right         : 0;
  border        : solid transparent;
  content       : " ";
  height        : 0;
  width         : 0;
  position      : absolute;
  pointer-events: none;
}

.mat-select-arrow:after {
  border-color    : rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width    : 7px;
  margin-left     : -8px;
  margin-top      : -2px;
}

.mat-select-arrow:before {
  border-color    : rgba(67, 164, 15, 0);
  border-top-color: #000000;
  border-width    : 7px;
  margin-left     : -9px;
}

/*Text alignments*/
.textRight {
  text-align: right !important;

  .mat-sort-header-container {
    justify-content: flex-end !important;
  }
}

.textCenter {
  text-align: center !important;

  .mat-sort-header-container {
    justify-content: center !important;
  }
}

/*Progress Bar*/
.customProgressbar {
  &.mat-progress-bar {
    height       : 5px;
    border-radius: 50px;
    overflow     : inherit;

    .mat-progress-bar-background {
      opacity: 0;
    }

    .mat-progress-bar-buffer {
      background-color: #daddd4;
      height          : 3px !important;
      top             : 1px;
    }
  }
}

/*Comment popup*/
.cardCheck {
  mat-checkbox {
    width: 80%;

    label {
      width: 100%;

      .mat-checkbox-label {
        width        : 100%;
        white-space  : nowrap;
        text-overflow: ellipsis;
        overflow     : hidden;
      }
    }
  }
}

.marginAuto {
  margin: auto !important;
}

.commentContent {

  .receiverContent,
  .senderContent {
    border       : 1px solid #e3e2e2;
    border-radius: 5px;
    padding      : 20px;
    width        : 70%;
    margin-bottom: 8px;

    .paragraph {
      font-size  : 18px;
      margin-top : 15px;
      font-family: "sf_ui_displayregular";
    }
  }

  .receiverContent {
    background-color: #f9f9f9;
    float           : left;
  }

  .senderContent {
    background-color: #fff;
    float           : right;
  }
}

.commentBoxActions {
  position     : relative;
  padding      : 5px 5px 5px 5px !important;
  height       : 60px;
  width        : 100%;
  border       : 1px solid #a0a2a5;
  border-radius: 5px;

  textarea {
    height   : 50px;
    width    : 85%;
    font-size: 14px;
  }

  textarea.mat-input-element {
    resize  : none !important;
    overflow: auto;
  }

  button {
    position    : absolute;
    right       : 0;
    margin-right: 5px !important;
  }
}

/*common classes*/
// .mat-drawer, .mat-drawer-inner-container{
//     overflow: inherit !important;
// }
// .mat-checkbox-checkmark-path {
//   stroke-dasharray: 19.91026 !important;
//   stroke-width: 3.13333px !important;
// }

.permissionTable {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: #2a8fcb;
  }

  tbody tr td.mat-column-select:first-child .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: #092541;
  }
}

.ORblock {
  position: relative;

  &.borderR:after {
    content     : "OR";
    display     : block;
    width       : 50px;
    height      : 40px;
    background  : #fff;
    text-align  : center;
    line-height : 40px;
    position    : absolute;
    top         : 50%;
    margin-top  : -20px;
    right       : 0;
    margin-right: -25px;
    font-size   : 14px;
  }
}

.secondaryTabs {
  .mat-tab-header {
    background: transparent;
    border    : 0;

    .mat-tab-label-content a {
      padding: 20px 0 5px;

      .mat-ripple-element {
        display: none;
      }
    }
  }
}

.hidden {
  display: none;
}

.disabledDiv {
  opacity: 0.5;
}

.divider {
  height    : 1px;
  background: #eeeeee;
}

.mat-card {
  color: #4a4a4a;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #739d44;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #739d44;
}

.mat-radio-outer-circle {
  border-color: #9b9b9b;
  width       : 18px !important;
  height      : 18px !important;
  border-width: 1px !important;
}

.mat-radio-inner-circle {
  height: 18px !important;
  width : 18px !important;
}

.mat-radio-label-content {
  padding-left: 5px !important;
}

/* .catalogImgUploadBtn {
  width: 120px;
  height: 120px;
  border: 2px solid #c7d1dc;
  position: relative;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  img {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 6px;
  }
  .imgUploadActions {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: -30px;
    margin-right: -10px;
    z-index: 1;
    .roundBtn {
      margin-right: 0px;
      padding: 0 !important;
      width: 0;
      height: 0;
      &:last-child {
        margin-right: 0;
      }
      i:before {
        color: #739d44;
      }
    }
  }
} */

.catalogImgUploadBtn {
  width        : 120px;
  height       : 120px;
  border       : 2px solid #c7d1dc;
  position     : relative;
  box-shadow   : 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  img {
    width        : 100%;
    height       : 100%;
    position     : relative;
    border-radius: 6px;
  }

  .imgUploadActions {
    position     : absolute;
    bottom       : 0;
    right        : 0;
    margin-bottom: -20px;
    margin-right : 10px;

    .roundBtn {
      background  : #fff;
      border      : 2px solid #c7d1dc;
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }

      i:before {
        color: #739d44;
      }
    }
  }
}

.mat-form-field-required-marker {
  color: #d0021b;
}

.imgIcon {
  width     : 100%;
  margin    : auto;
  display   : block;
  margin-top: 5px;
}

.mat-error {
  font-size: 12px;
}

.displayBlock {
  display: block !important;
}

.borderR {
  border-right: 1px solid #c7d1dc;
}

.vAlignTop {
  vertical-align: top !important;
}

.w-100per .mat-form-field-infix {
  width: 100%;
}

.width-100Per {
  width: 100%;
}

.w-490 {
  width: 490px !important;
}

.pageLeft {
  margin-right: 20px;
}

.pageLeft,
.pageRight {
  margin-top: 10px;
}

.pageHeader {
  padding-right: 20px;

  >span,
  >div>span {
    margin-right : 5px;
    margin-bottom: 0;
    margin-top   : 0;
  }

  h2 {
    font-size    : 20px;
    margin-right : 15px;
    margin-bottom: 0;
    margin-top   : 0;
  }

  .customBadge {
    margin-right: 30px;
  }
}

.headerWithIcon {
  margin-bottom: 25px;
  width        : 100%;

  h3 {
    font-size  : 16px;
    font-family: "sf_ui_displaysemibold";
    margin     : 0;
  }

  >span:before {
    color    : #777;
    font-size: 18px;
  }
}

.panelHeader {

  >span,
  h3 {
    margin-right : 15px;
    margin-bottom: 0;
    margin-top   : 0;
  }
}

.customBadge {
  .mat-badge-content {
    border          : 1px solid #2a8fcb;
    background-color: transparent;
    border-radius   : 50px;
    color           : #2a8fcb;
    min-width       : 55px;
    max-width       : 100px;
    height          : 22px;
    position        : relative;
    font-family     : "sf_ui_displayregular";
    top             : 3px !important;
    right           : 0 !important;
  }
}

.d-flex {
  display: flex;
}

.pdtb-2 {
  padding: 2px 0 !important;
}

.pd-20 {
  padding: 20px;
}

.pd-10 {
  padding: 10px;
}

.pdt-0 {
  padding: 0;
}

.pdt-8 {
  padding-top: 8px;
}

.pdt-10 {
  padding-top: 10px;
}

.pdt-20 {
  padding-top: 20px;
}

.pdt-30 {
  padding-top: 30px;
}

.pdb-15 {
  padding-bottom: 15px;
}

.pdb-0 {
  padding-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb--30 {
  margin-top: -30px !important;
}

.pdtlr-20 {
  padding: 20px 20px 0;
}

.pdlr-20 {
  padding: 0 20px !important;
}

.pdlr-60 {
  padding-left : 60px !important;
  padding-right: 60px !important;
}

.pdr-0 {
  padding-right: 0;
}

.pdr-20 {
  padding-right: 20px;
}

.pdr-50 {
  padding-right: 50px;
}

.pdl-50 {
  padding-left: 50px;
}

.pd-0 {
  padding: 0 !important;
}

.ml-80 {
  margin-left: 80px;
}

.mr-30 {
  margin-right: 30px;
}

.w-20 {
  width: 20px;
}

.w-180 {
  width: 180px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-0 {
  margin-right: 0 !important;
}

.pdr-0 {
  padding-right: 0 !important;
}

.mr-15 {
  margin-right: 15px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr--10 {
  margin-right: -10px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.ml-40 {
  margin-left: 40px;
}

.ml--10 {
  margin-left: -10px;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-23 {
  margin-left: 23px;
}

.mt--18 {
  margin-top: -18px;
}

.mt--20 {
  margin-top: -20px !important;
}

.mt-0 {
  margin-top: 0;
}

.mt--10 {
  margin-top: -10px;
}

.mt--5 {
  margin-top: -5px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt--30 {
  margin-top: -30px !important;
}

.mt-40 {
  margin-top: 40px;
}

.logoText {
  font-family: "sf_ui_displaybold";
}

.fs-24 {
  font-size: 24px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-39 {
  font-size: 39px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 9px !important;
}

.alignFlexEnd {
  justify-content: flex-end;
}

.lightText {
  color: #9b9b9b;

  .icon-chat_message:before,
  .icon-system_generated_message:before {
    color: #9b9b9b;
  }
}

.fw-300 {
  font-weight: 300;
}

.fw-600 {
  font-family: "sf_ui_displaysemibold";
}

.bgNone {
  background: none;
}

.imgLabel {
  color        : #4a4a4a;
  font-size    : 16px;
  font-family  : "sf_ui_displayregular";
  margin-bottom: 10px;

  >span {
    margin-right: 10px;
    font-size   : 16px;
  }
}

.rBorder {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.infoBlocks {

  .readOnlyField,
  .formControl {
    margin-right : 35px;
    padding-right: 35px;
  }
}

.editableInput {
  padding   : 15px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border    : 1px solid rgba(0, 0, 0, 0.1);
  font-size : 16px;

  &:focus {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    outline   : 0;
  }
}

// table td.editableTd{
//     padding: 0 !important;
// }
.editableTd {
  white-space  : nowrap;
  padding-left : 10px;
  padding-right: 10px;

  &.multipleInput {
    padding: 0 !important;

    input {
      display: inline-block;
      width  : 42%;
      height : 4vh;
      padding: 18px 4%;

      &.newUpdate {
        // background-color: rgba(233, 103, 15, .2);
        box-shadow: inset 0px 0px 0px 1px #e9670e !important;
      }

      &.errored {
        // background-color: rgba(233, 103, 15, .2);
        // box-shadow:inset 0px 0px 0px 1px red !important;
        color: red !important;
      }

      &:disabled {
        color      : #aaa; //9b9b9b
        font-family: "sf_ui_displaymedium";
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.editableTd:hover,
.editableTd:focus {
  box-shadow: inset 0px 0px 0px 2px #739d44 !important;
}

u {
  text-decoration: none;
}

.mat-card {
  font-family: "sf_ui_displayregular" !important;
  padding    : 20px !important;
}

.mBold {
  font-family: "sf_ui_displaysemibold" !important;
}

.regularFont {
  font-family: "sf_ui_displayregular";
}

.xlBold {
  font-family: "sf_ui_displaybold" !important;
}

.newMsgIndicator {
  width           : 10px;
  height          : 10px;
  background-color: #2a8fcb;
  border-radius   : 50%;
}

.wAuto {
  width: auto;
}

.pullRight {
  float: right;
}

.dateRange {
  .mat-icon {
    vertical-align: middle;
    margin-top    : -15px;

    span {
      font-weight: bold;
    }
  }
}

.dateRange .w-180 .mat-form-field-wrapper .mat-form-field-infix {
  width: 90px !important;
}

.withoutLabel {
  .mat-form-field-infix {
    border    : 0;
    padding   : 0 0 5px;
    top       : 1px;
    text-align: left;
  }

  .mat-form-field-underline {
    top: 18px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.horizontalGroup {

  // .fieldValue{
  //   width:auto;
  //   white-space: normal;
  // }
  .mat-form-field-infix {
    border    : 0;
    padding   : 0 0 5px;
    top       : 1px;
    text-align: left;
  }

  .mat-form-field-underline {
    top: 18px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 1em !important;
  }

  date-picker {
    display   : inline-block;
    position  : relative;
    text-align: left;
  }
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: none !important;
}

.mat-expansion-indicator::after {
  margin-top: -5px;
}

.mat-checkbox {
  font-family: "sf_ui_displayregular";
}

.circularCheck {
  .mat-checkbox-frame {
    border-radius: 100%;
    background   : #daddd4;
    border-width : 0 !important;
    width        : 18px;
    height       : 18px;
  }

  .mat-checkbox-background {
    border-radius: 100%;
    width        : 18px;
    height       : 18px;
  }

  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: #2a8fcb !important;
  }

  .mat-checkbox-checkmark {
    top  : 2px;
    left : 3px;
    width: 70%;
  }

  .mat-checkbox-checkmark-path {
    stroke           : #fff !important;
    stroke-dashoffset: 0 !important;
  }
}

/*Filter box*/
.filterBox {
  &.dashboardFilter {
    min-width: 300px;
    max-width: 300px;
  }

  padding: 20px;

  .divider {
    height    : 1px;
    background: #eeeeee;
    margin    : 10px 0;
  }

  .mat-form-field-infix {
    width    : 115px;
    font-size: 14px;
  }

  mat-form-field.From {
    margin-right: 20px;
  }

  .mat-checkbox {
    margin-right: 20px;
    font-size   : 14px;
  }

  .section {
    .sectionHead {
      margin-bottom: 10px;
      font-family  : "sf_ui_displaysemibold";
      font-size    : 14px;
    }
  }

  .mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {
      // padding-bottom: 0;
    }

    .mat-form-field-underline {
      top: 35px;
    }
  }

  .filterBtn {
    border-radius   : 50px;
    background-color: #eeeeee;
    height          : 20px;
    line-height     : 23px;
    border          : 0;
    font-family     : "sf_ui_displayregular";
    font-size       : 12px;
    color           : #4a4a4a;
    margin-right    : 10px !important;
    padding         : 0 15px;
    cursor          : pointer;
    outline         : none;

    &:hover {
      background-color: #082840;
      color           : #fff;
    }

    &.active {
      background-color: #082840;
      color           : #fff;
    }
  }
}

/* Collapsible panel */
.mat-expansion-panel-body {
  padding   : 20px !important;
  background: #fff;
  position  : relative;
}

// .mat-expansion-panel-body:after {
//   bottom: 100%;
//   right: 0;
//   border: solid transparent;
//   content: " ";
//   height: 0;
//   width: 0;
//   position: absolute;
//   pointer-events: none;
//   border-color: rgba(255, 255, 255, 0);
//   border-bottom-color: #ffffff;
//   border-width: 10px;
//   margin-right: 80px;
// }
.mat-expansion-panel-header {
  padding: 0 20px !important;
}

.CollapseBtn {
  font-family: "sf_ui_displayregular";
  font-size  : 18px;
  color      : #739d44;
}

.mat-expansion-indicator::after {
  color: #739d44;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1) !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
  background: none !important;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius : 0;
}

.customTable {
  border-collapse: collapse;

  &.demandTable {
    td {
      input {
        font-size : 14px;
        border    : none;
        display   : inline-block;
        width     : 96px;
        height    : 39px;
        padding   : 0;
        text-align: center;

        &:focus {
          outline: 0 none;
        }
      }
    }

    .multipleInput {
      padding: 0 !important;

      input {
        padding: 0 10px !important;
        margin : 0 10px !important;

        &:first-child {
          border: 0;
        }

        &.newUpdate {
          // background-color: rgba(233, 103, 15, .2);
          box-shadow: none !important;
        }

        &:disabled {
          color      : #aaa; //9b9b9b
          font-family: "sf_ui_displaymedium";
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  th {
    border-top    : 1px solid #739d44;
    padding       : 8px 10px !important;
    border-bottom : 1px solid #739d44 !important;
    border-right  : 1px solid #e3e2e2;
    border-left   : 1px solid #e3e2e2;
    color         : #4b4b4b;
    font-size     : 14px;
    font-family   : "sf_ui_displaysemibold";
    line-height   : 1.75;
    letter-spacing: 0.1px;
    text-align    : left;
  }

  td {
    //padding: 0px !important;
    border   : 1px solid #e3e2e2;
    font-size: 14px;

    &.expandCta {
      position: relative;

      span {
        position: absolute;
        display : block;
        top     : 0;
        bottom  : 0;
        left    : 0;
        right   : 0;
        cursor  : pointer;

        &::before {
          content     : "";
          width       : 0;
          height      : 8px;
          border-left : 6px solid transparent;
          border-right: 6px solid transparent;
          position    : absolute;
          top         : 50%;
          right       : 12px;
        }

        &.collapsed {
          &::before {
            border-top: 8px solid #9b9b9b;
            margin-top: -3px;
          }
        }

        &.expanded {
          &::before {
            border-bottom: 8px solid #9b9b9b;
            margin-top   : -10px;
          }
        }
      }
    }

    input {
      font-size: 14px;
      border   : none;

      width  : 92%;
      height : 40px;
      padding: 8px 4%;

      &:focus {
        outline-offset: -2px;
        outline-color : #739d44;
        outline-width : 2px;
      }
    }

    &.tablePadding {
      padding: 8px 10px !important;
    }
  }

  .element-details {
    transition: all 0.3s ease;
    padding   : 0;
  }

  tr {
    &.close {
      td {
        padding: 0 !important;
        border : 0;

        .element-details {
          height  : 0;
          overflow: hidden;
          opacity : 0;
        }
      }
    }

    &.open {
      >td {
        padding: 0 !important;

        .element-details {
          opacity: 1;

          &.getPadding {
            padding: 30px 42px;
          }
        }
      }

      th {
        border-top   : 1px solid #9b9b9b !important;
        border-bottom: 1px solid #9b9b9b !important;
      }

      thead {
        th:first-child {
          border-left: 0 !important;
        }

        th:last-child {
          border-right: 0 !important;
        }
      }

      tbody {
        td:first-child {
          border-left: 0 !important;
        }

        td:last-child {
          border-right: 0 !important;
        }
      }
    }
  }

  .addBtn {
    border-radius   : 3px;
    border          : solid 1px #4a4a4a;
    background-color: #ffffff;
    font-size       : 16px;
    font-family     : "sf_ui_displaysemibold";
    color           : #2a3037;
    padding         : 5px 16px;
    cursor          : pointer;
  }

  ::placeholder {
    color  : #c4c4c4;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #c4c4c4;
  }

  ::-ms-input-placeholder {
    color: #c4c4c4;
  }

  .editableTd {
    &.multipleInput {
      input {
        padding-bottom: 4px;
        padding-top   : 27px;
        // padding: 18px 4%;
      }
    }
  }

  .readOnlyField .fieldLabel,
  .readOnlyField .fieldValue {
    font-size: 14px;
  }

  .readOnlyField .fieldValueWt {
    font-size: 9px;
    color    : #717171;

    font-family: "sf_ui_displaybold";
  }

  &.customTableWithForm {
    td {
      input {
        height: 20px;
      }

      mat-form-field {
        width: auto;
      }

      .mat-form-field-underline {
        display: block;
        height : 1px;
        top    : 18px;
      }
    }
  }
}

.deleteBtn {
  cursor: pointer;
}

.basicCards {
  border-radius: 3px !important;
  box-shadow   : 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;

  &.supplierProfileImg {
    width       : 250px;
    height      : 160px;
    overflow    : hidden;
    margin-right: 20px;

    img {
      height : 100%;
      margin : auto;
      display: block;
    }
  }
}

.reminderFilter {
  [class^="icon-"] {
    margin-right : 10px;
    margin-bottom: 2px;

    &:before {
      font-size  : 20px;
      color      : #739d44;
      font-weight: 600;
    }
  }

  .mat-menu-item {
    line-height  : 40px;
    height       : 40px;
    border-bottom: 1px solid #eeeeee;
    padding      : 0 12px;

    &:last-child {
      border-bottom: 0;
    }

    span {
      vertical-align: middle;
      display       : inline-block;
    }
  }

  .mat-menu-content {
    padding-top   : 0 !important;
    padding-bottom: 0 !important;
  }
}

.mat-slide-toggle {
  transform  : scale(1);
  font-weight: 400;
  color      : #ccc;
  height     : 20px !important;
  line-height: 20px !important;

  .mat-slide-toggle-bar {
    transform       : scale(1);
    order           : 2;
    margin          : 0;
    background-color: #e0e0e0;
    height          : 18px;
    border-radius   : 50px;
    width           : 40px;

    .mat-slide-toggle-thumb-container {
      top: 0;

      .mat-slide-toggle-thumb {
        border       : 0.5px solid #e0e0e0;
        height       : 16px;
        width        : 16px;
        border-radius: 50%;
      }
    }
  }

  &.mat-checked {
    &:before {
      content  : attr(data-target);
      font-size: 11px;
      color    : #fff;
      display  : block;
      position : absolute;
      left     : 12px;
      z-index  : 1;
      top      : 1px;
    }

    .mat-slide-toggle-bar {
      background-color: #00838F !important;
    }

    .mat-slide-toggle-thumb {
      background-color: #fff !important;
      border-color    : #2a8fcb !important;
    }

    .mat-ripple-element {
      background-color: #00838F !important;
    }

    .mat-slide-toggle-thumb-container {
      -webkit-transform: translate3d(20px, 0, 0) !important;
      transform        : translate3d(20px, 0, 0) !important;
    }
  }

  .mat-slide-toggle-content {
    margin-right: 5px;
  }
}

.customList {
  .customItems {
    margin-bottom : 10px;
    padding-bottom: 10px;
    border-bottom : 1px solid #eeeeee;
    width         : 100%;

    .mat-form-field {
      display: block;
    }

    &:last-child {
      margin : 0;
      border : 0;
      padding: 0;
    }

    &.noBorder {
      border : 0;
      padding: 0;
    }

    &.editableCustomItem {
      padding-bottom: 0;

      .mat-form-field-infix {
        border-top: 0;
      }

      .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding-bottom: 5px;
      }

      .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-appearance-legacy .mat-form-field-label {
        margin-top: 6px;
      }
    }

    >.reminderText {
      margin: 0 15px;
      width : 100%;

      div {
        margin-bottom: 5px;
      }

      .statusTag {
        margin-right: 10px;
        display     : inline-block;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .mat-icon-button {
      width : 20px;
      height: 20px;

      .mat-icon,
      .mat-icon-button i {
        line-height: 0px;
      }
    }
  }
}

.msgBox {
  border-bottom: 1px solid #eeeeee !important;
  box-shadow   : 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;
  transition   : all 0.3s;

  &.unreadMsg {
    background-color: #fff;

    .msgHead {
      span {
        font-family: "sf_ui_displaybold";
      }
    }

    .fieldValue {
      font-family: "sf_ui_displaybold";
    }

    .newMsgIndicator {
      opacity: 1;
    }
  }

  &.readMsg {
    background-color: #f9f9f9;

    .msgHead {
      span {
        font-size  : 16px;
        font-family: "sf_ui_displayregular";
      }
    }

    .fieldValue {
      font-family: "sf_ui_displayregular";
    }

    .newMsgIndicator {
      opacity: 0;
    }
  }

  &:first-child {
    border-radius: 3px 3px 0 0;
    margin-top   : 0;
  }

  &:last-child {
    border-radius: 0 0 3px 3px;
  }

  .icon-discard {
    padding-bottom: 5px;
    display       : inline-block;
  }

  .lightText {
    .mat-icon-button {
      margin-right: -10px;
    }
  }

  .msgHead {
    span {
      font-size: 16px;
    }
  }

  .msgActions {
    opacity   : 0;
    transition: all 0.3s;
    margin-top: -30px;

    input {
      border       : 0;
      border-bottom: 1px solid #f57f23;
      max-width    : 45px;
      display      : inline-block;
      padding      : 0 0;
      margin       : 0px 5px;
      font-size    : 12px;

      &:focus {
        outline: none;
      }
    }
  }

  &:hover {
    z-index         : 1;
    box-shadow      : 0 6px 24px 0 rgba(0, 0, 0, 0.2) !important;
    background-color: #fff;

    .msgActions {
      opacity   : 1 !important;
      margin-top: -5px;
      transition: all 0.1s;
    }
  }

  .fieldLabel,
  .fieldValue {
    font-size  : 14px;
    font-family: "sf_ui_displayregular";
  }

  .btn {
    padding     : 5px 10px !important;
    margin-right: 10px !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

//(RD)PMB-PMB-6364 Task Center Enhancements for better look and feel
// :root { 
//   .mat-tab-body-wrapper {
//     // margin-left: -20px;
//     // margin-right: -20px;
//     height: calc(100vh - 64px);
//   }
// }

// .lgTabs {
//   .mat-tab-body-wrapper {
//     // margin-left: -20px;
//     // margin-right: -20px;
//   }
//   mat-card.msgBox {
//     margin-left: 20px;
//     margin-right: 20px;
//   }
//   mat-tab-header {
//     background: #f9f9f9;
//     // margin-left: -20px;
//     // margin-right: -20px;
//     // margin-top: -20px;
//     .mat-tab-nav-bar,
//     .mat-tab-header {
//       border-bottom: 1px solid #dde1e6;
//     }
//     .mat-tab-label-content {
//       display: block;
//       .tabCount {
//         font-size: 30px;
//         font-family: "sf_ui_displayregular";
//         color: #4a4a4a !important;
//         margin-top: 5px;
//       }
//       .label {
//         font-family: "sf_ui_displaymedium";
//         font-size: 18px;
//         color: #4a4a4a !important;
//       }
//     }
//     .mat-tab-label {
//       border-right: 1px solid #e1e1e1;
//       padding: 15px 35px;
//       height: auto;
//       opacity: 1;
//       &.mat-tab-label-active {
//         border-bottom: 1px solid #739d44 !important;
//         font-weight: 600 !important;
//         box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.1);
//         background-color: #ece8e8;
//         .tabCount {
//           color: #2a3037 !important;
//         }
//       }
//     }
//     .mat-ink-bar {
//       background-color: transparent !important;
//     }
//   }
// }
.lgTabs {
  position : absolute;
  width    : 100%;
  overflow : hidden !important;
  height   : 100%;
  // height: -moz-available;
  // height: -webkit-fill-available;
  // height: fill-content;
  // height: stre;

  .mat-tab-body-wrapper {
    // margin-left: -20px;
    // margin-right: -20px;
    height: 100%;
  }

  .mat-tab-body-content {
    height  : 100% !important;
    overflow: hidden;
  }

  mat-card.msgBox {
    margin-left : 20px;
    margin-right: 20px;
  }

  mat-tab-header {
    background: #f9f9f9;

    // margin-left: -20px;
    // margin-right: -20px;
    // margin-top: -20px;
    .mat-tab-nav-bar,
    .mat-tab-header {
      border-bottom: 1px solid #dde1e6;
    }

    .mat-tab-label-content {
      display: block;

      .tabCount {
        font-size  : 30px;
        font-family: "sf_ui_displayregular";
        color      : #4a4a4a !important;
        margin-top : 5px;
      }

      .label {
        font-family: "sf_ui_displaymedium";
        font-size  : 18px;
        color      : #4a4a4a !important;
      }
    }

    .mat-tab-label {
      border-right: 1px solid #e1e1e1;
      padding     : 15px 35px;
      height      : auto;
      opacity     : 1;

      &.mat-tab-label-active {
        border-bottom   : 1px solid #739d44 !important;
        font-weight     : 600 !important;
        box-shadow      : inset 0 0 6px 0 rgba(0, 0, 0, 0.1);
        background-color: #ece8e8;

        .tabCount {
          color: #2a3037 !important;
        }
      }
    }

    .mat-ink-bar {
      background-color: transparent !important;
    }
  }
}

.mdTabs {
  mat-tab-header {
    background   : #f9f9f9;
    margin-left  : -20px;
    margin-right : -20px;
    margin-top   : -20px;
    border-bottom: 1px solid #eeeeee;

    .mat-tab-label-content {
      display: block;

      a {
        font-family    : "sf_ui_displaymedium";
        font-size      : 16px;
        font-weight    : 600;
        color          : #4a4a4a;
        text-decoration: none;
        padding        : 20px 0;
        display        : inline-block;
        position       : relative;

        &:after {
          content   : "";
          width     : 100%;
          height    : 2px;
          background: transparent;
          bottom    : 0;
          left      : 0;
          position  : absolute;
        }
      }

      .mat-ripple {
        display: none;
      }

      .mat-ripple-element {
        display: none;
      }
    }

    .mat-tab-label {
      padding  : 0 20px;
      position : relative;
      height   : auto;
      opacity  : 1;
      min-width: auto !important;

      &.mat-tab-label-active {
        a:after {
          background: #f9f9f9;
        }
      }
    }

    .mat-ink-bar {
      background-color: transparent !important;
      display         : none;
    }
  }
}

.productCard {
  position        : relative;
  width           : 320px;
  height          : auto;
  box-shadow      : 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff;
  margin          : 10px 10px 10px 1px;
  padding         : 0 !important;
  border-radius   : 0 !important;

  .overlapHeader {
    margin-top: -26px;
    position  : relative;
    padding   : 0 15px !important;

    .mat-card-title {
      color      : #fff;
      font-size  : 14px;
      font-family: "sf_ui_displayregular";
      font-weight: normal;
    }
  }

  .mat-card-header {
    padding: 20px 15px 5px;

    .mat-card-header-text {
      margin: 0;
    }

    .mat-card-header .mat-card-title {
      margin-bottom: 5px;
    }

    .cardAmt {
      color      : #e9670e;
      font-size  : 16px;
      font-family: "sf_ui_displayregular";
    }

    .mat-card-title {
      margin-bottom: 5px !important;
      margin-top   : -5px;
    }

    .mat-card-subtitle {
      margin-bottom: 0;
    }
  }

  .mat-card-content {
    padding: 0 15px 20px;
    top    : -25px;
  }

  .cardActions {
    input {
      width        : 60px;
      border       : 0;
      border-bottom: 1px solid #c7d1dc;
      font-size    : 14px;
      margin-right : 10px;
    }

    button {
      font-size    : 12px;
      padding      : 4px 10px !important;
      min-width    : auto;
      border-radius: 3px;
    }
  }

  .cardImg {
    position: relative;
    width   : 100%;
    height  : 135px;
    overflow: hidden;
    top     : 0;
    left    : 0;

    img {
      position: absolute;
      width   : 100%;
      margin  : 0;
    }

    &.fixedImg {
      display    : flex;
      align-items: center;

      img {
        position  : relative;
        width     : auto !important;
        margin    : auto;
        display   : block;
        margin-top: 20px;
      }
    }
  }

  .fillChips .mat-chip-list-wrapper {
    margin: 0;

    .mat-chip.mat-standard-chip {
      background-color: #f57f23;
      color           : #fff;
      margin          : 5px 15px;
      position        : absolute;
      border-radius   : 0;
      z-index         : 1;
    }
  }

  .borderedChips {
    width      : 100%;
    border-top : 1px solid #c7d1dc;
    padding-top: 10px;
    margin-top : 4px !important;

    .mat-chip-list-wrapper {
      margin: 0;

      .mat-chip.mat-standard-chip {
        background-color: #fff;
        color           : #2a3037;
        font-size       : 12px;
        border          : 1px solid #c7d1dc;
        min-height      : auto;
        padding         : 10px 10px;
        margin          : 0 10px 0 0;
      }
    }
  }
}

.productCardForInfo {
  position        : relative;
  height          : auto;
  background-color: transparent !important;
  padding         : 0 !important;
  border-radius   : 0 !important;
  width           : 100%;
  box-shadow      : none !important;

  .mat-card-header {
    padding: 0;

    .mat-card-header-text {
      margin: 0;
    }

    .mat-card-header .mat-card-title {
      margin-bottom: 5px;
    }

    .mat-card-title {
      margin-bottom: 5px !important;
      margin-top   : -5px;
      font-family  : "sf_ui_displaymedium";
    }

    .mat-card-subtitle {
      margin-bottom: 0;
    }
  }

  .mat-card-content {
    padding: 10px 0 0;

    p {
      margin-top   : 40px;
      margin-bottom: 5px;
    }

    .smallLink {
      font-size      : 14px;
      text-decoration: none;
      color          : #739d44;
      font-family    : "sf_ui_displaymedium";
    }
  }

  .cardActions {
    input {
      width        : 60px;
      border       : 0;
      border-bottom: 1px solid #c7d1dc;
      font-size    : 14px;
      margin-right : 10px;
    }

    button {
      font-size    : 12px;
      padding      : 4px 10px !important;
      min-width    : auto;
      border-radius: 3px;
    }
  }

  .cardImg {
    position: relative;
    width   : 100%;
    height  : 135px;
    overflow: hidden;
    top     : 0;
    left    : 0;

    img {
      position: absolute;
      width   : 100%;
      margin  : 0;
    }
  }

  .fillChips .mat-chip-list-wrapper {
    margin: 0;

    .mat-chip.mat-standard-chip {
      background-color: #f57f23;
      color           : #fff;
      margin          : 5px 15px;
      position        : absolute;
      border-radius   : 0;
      z-index         : 1;
    }
  }

  .borderedChips {
    width      : 100%;
    border-top : 1px solid #c7d1dc;
    padding-top: 10px;
    margin-top : 4px !important;

    .mat-chip-list-wrapper {
      margin: 0;

      .mat-chip.mat-standard-chip {
        background-color: #fff;
        color           : #2a3037;
        font-size       : 12px;
        border          : 1px solid #c7d1dc;
        min-height      : auto;
        padding         : 10px 10px;
        margin          : 0 10px 0 0;
      }
    }
  }
}

.swiper-container {
  width: 95% !important;

  .swiper-button-next {
    background   : rgba(255, 255, 255, 0.9) !important;
    width        : 40px;
    height       : 60px;
    border       : 1px solid #739d44;
    border-radius: 3px 0 0 3px;
    margin-right : -11px;

    &.swiper-button-disabled {
      border-color: #c7d1dc;

      &:before {
        border-color: #c7d1dc;
      }
    }

    &:before {
      content          : "";
      position         : relative;
      border           : solid #739d44;
      border-width     : 0 3px 3px 0;
      display          : inline-block;
      padding          : 6px;
      transform        : rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      margin           : 25px 0px 0px 10px;
    }
  }

  .swiper-button-prev {
    background   : rgba(255, 255, 255, 0.9) !important;
    width        : 40px;
    height       : 60px;
    border       : 1px solid #739d44;
    border-radius: 0 3px 3px 0;
    left         : 0;

    &.swiper-button-disabled {
      border-color: #c7d1dc;

      &:before {
        border-color: #c7d1dc;
      }
    }

    &:before {
      content          : "";
      position         : relative;
      border           : solid #739d44;
      border-width     : 0 3px 3px 0;
      display          : inline-block;
      padding          : 6px;
      transform        : rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin           : 25px 10px 0px 15px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    top       : 50%;
    margin-top: -30px;
  }

  .swiper-pagination {
    position  : relative;
    margin-top: 20px;
  }

  .swiper-pagination-bullet-active {
    background: #d8d8d8;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-first {
    border: 0 !important;
  }

  .swiper-pagination-bullet {
    width : 4px;
    height: 4px;
  }

  .swiper-pagination-bullet-active {
    width : 6px;
    height: 6px;
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 1;
  }
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  padding: 0 0 0 4px !important;
}

.mat-tree {
  padding-right: 20px;

  .mat-nested-tree-node {
    ul {
      padding-left: 0;

      &>mat-tree-node,
      &>mat-nested-tree-node>li {
        padding-left: 20px;
      }

      .mat-tree-node {
        border: 0;
      }
    }
  }

  .mat-tree-node {
    position     : relative;
    font-size    : 16px;
    border-bottom: 1px solid #e3e2e2;

    button.mat-icon-button {
      width        : 100%;
      height       : auto;
      line-height  : 50px;
      border-radius: 0;
      position     : absolute;
      right        : 0;

      .mat-button-wrapper {
        float: right;
      }
    }
  }
}

// PO's Carousel
.swiper-pos {
  width: 100%;

  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        width           : 1000px !important;
        min-height      : 300px !important;
        height          : auto;
        background-color: #ffffff;
        border-radius   : 3px;
        box-shadow      : 0 1px 8px 0 rgba(0, 0, 0, 0.28);
        margin-top      : 20px;
        margin-bottom   : 10px;
        max-height      : 100%;
        overflow        : inherit !important;
        position        : relative;

        &:first-of-type {
          margin-left: 23px;
        }

        .load-pos-count {
          position     : absolute;
          top          : -10px;
          left         : -15px;
          height       : 30px;
          width        : 30px;
          line-height  : 33px;
          border-radius: 50%;
          box-shadow   : 0 1px 8px 0 rgba(0, 0, 0, 0.28);
          text-align   : center;
          background   : #ffffff;
        }
      }
    }
  }
}

.draggeble-wrapper {
  margin       : 20px;
  border       : 1px dashed #43a40f;
  height       : 100%;
  height       : calc(275px - 40px);
  border-radius: 5px;

  p {
    font-size   : 14px;
    font-weight : bold;
    font-style  : normal;
    font-stretch: normal;
    line-height : 1.29;
    color       : #4a4a4a;
    width       : 130px;
  }
}

.dropped-po-wrapper {
  .drag-pos-here {
    border-radius  : 3px;
    border         : dashed 1px #739d44;
    width          : 150px;
    height         : 120px;
    font-family    : "sf_ui_displayregular";
    font-size      : 14px;
    font-weight    : bold;
    font-style     : normal;
    font-stretch   : normal;
    line-height    : 1.29;
    letter-spacing : 0.2px;
    text-align     : center;
    color          : #4a4a4a;
    display        : flex;
    justify-content: center;
    align-items    : center;
  }

  .mat-form-field-infix {
    width: 160px;
  }

  .freight-charge {
    position: relative;
    z-index : 0;

    .add-freight-charge {
      position: absolute;
      right   : -5px;
      top     : 5px;
      z-index : 1;

      &.mat-icon-button {
        width      : 30px;
        height     : 30px;
        line-height: 30px;
      }
    }
  }
}

/*Process wizard material v 6.4.7*/

/* .regRight {
  .mat-horizontal-stepper-header-container {
    margin-top: 0;
  }
}
.mat-horizontal-stepper-header-container {
  margin-bottom: 40px;
  margin-top: 40px;
}
.mat-horizontal-stepper-header {
  float: left !important;
  width: 200px;
  height: auto !important;
  display: block !important;
  padding: 0 0 0 0 !important;
  overflow: inherit !important;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #daddd4;
    position: absolute;
    top: 7px;
    right: 0;
    left: 50%;
    margin-left: 8px;
  }
  &[ng-reflect-active="true"] {
    .mat-step-icon,
    .mat-step-icon-not-touched {
      background-color: #fff;
      border-color: #739d44;
    }
  }
  &[ng-reflect-state="edit"] {
    .mat-step-icon,
    .mat-step-icon-not-touched {
      background-color: #739d44;
      border-color: #739d44;
    }
    &:after {
      background-color: #739d44;
    }
  }
  &:last-child:after {
    display: none;
  }
}
.mat-stepper-horizontal-line {
  display: none;
}
.mat-step-header {
  &:hover {
    background-color: transparent !important;
  }
  .mat-ripple {
    display: none !important;
  }
  .mat-step-icon,
  .mat-step-icon-not-touched {
    background-color: #daddd4;
    color: transparent;
    border: 2px solid #daddd4;
    height: 12px;
    width: 12px;
    position: relative;
    margin-right: inherit !important;
    margin: auto !important;
    display: block !important;
    &.mat-step-icon-selected {
      background-color: #fff;
      color: rgba(255, 255, 255, 0);
      border: 2px solid #739d44;
      font-size: 0;
    }
    &.mat-step-icon-state-edit {
      background-color: #739d44;
      color: rgba(255, 255, 255, 0);
      border: 2px solid #739d44;
    }
  }
  .mat-step-label {
    display: block !important;
    font-family: "sf_ui_displaysemibold";
    font-size: 14px;
    color: #4a4a4a !important;
    text-align: center;
    margin-top: -45px;
    &.mat-step-label-selected {
      color: #739d44 !important;
    }
  }
} */
.regRight {
  .mat-horizontal-stepper-header-container {
    margin-top: 0;
  }
}

.mat-horizontal-stepper-header-container {
  margin-bottom: 40px;
  margin-top   : 40px;
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
  float         : left !important;
  width         : 200px;
  height        : auto !important;
  display       : block !important;
  padding       : 0 0 0 0 !important;
  overflow      : inherit !important;

  &:after {
    content         : "";
    display         : block;
    width           : 100%;
    height          : 2px;
    background-color: #daddd4;
    position        : absolute;
    top             : 7px;
    right           : 0;
    left            : 50%;
    margin-left     : 8px;
  }

  &[ng-reflect-active="true"] {

    .mat-step-icon,
    .mat-step-icon-not-touched {
      background-color: #fff;
      border-color    : #739d44;
    }
  }

  &[ng-reflect-state="edit"] {

    .mat-step-icon,
    .mat-step-icon-not-touched {
      background-color: #739d44;
      border-color    : #739d44;
    }

    &:after {
      background-color: #739d44;
    }
  }

  &:last-child:after {
    display: none;
  }
}

.mat-stepper-horizontal-line {
  display: none;
}

.mat-step-header {
  &:hover {
    background-color: transparent !important;
  }

  .mat-ripple {
    display: none !important;
  }

  .mat-step-icon,
  .mat-step-icon-not-touched {
    background-color: #daddd4;
    color           : transparent;
    border          : 2px solid #daddd4;
    height          : 12px;
    width           : 12px;
    position        : relative;
    margin-right    : inherit !important;
    margin          : auto !important;
    display         : block !important;

    &.mat-step-icon-selected {
      background-color: #fff;
      color           : rgba(255, 255, 255, 0);
      border          : 2px solid #739d44;
      font-size       : 0;
    }

    &.mat-step-icon-state-edit {
      background-color: #739d44;
      color           : rgba(255, 255, 255, 0);
      border          : 2px solid #739d44;
    }
  }

  .mat-step-label {
    display    : block !important;
    font-family: "sf_ui_displaysemibold";
    font-size  : 14px;
    color      : #4a4a4a !important;
    text-align : center;
    margin-top : -45px;

    &.mat-step-label-selected {
      color: #739d44 !important;
    }
  }
}

.RegistrationPanel {
  background-color: #ffffff;
  overflow        : hidden;
  margin          : 0 auto;
  padding         : 60px 0 0 0;

  .iconicCheckBoxGroup {
    margin-top   : 110px;
    margin-bottom: 40px;

    .iconicCheckBoxContent {
      .iconicCheckBox {
        label.mat-checkbox-layout {
          text-align: center;
          display   : block;
          position  : absolute;
          width     : 100%;
          height    : 100%;

          span.mat-checkbox-label {
            display    : block;
            position   : relative;
            top        : -90px;
            font-size  : 18px;
            font-family: "sf_ui_displaymedium";
            color      : #2a3037;
          }

          .mat-checkbox-inner-container {
            display         : block;
            width           : 30px;
            height          : 30px;
            border-radius   : 100%;
            background-color: #fff;
            border          : 1px solid #739d44;
            top             : -15px;
            margin          : auto;

            .mat-checkbox-frame {
              opacity: 0;
            }

            .mat-checkbox-background {
              border-radius: 100%;
            }

            .mat-checkbox-ripple {
              .mat-ripple-element {
                border-radius: 100%;
              }

              .mat-checkbox-background,
              .mat-checkbox-frame {
                border-radius: 100%;
                border       : 1px solid #739d44;
              }
            }
          }
        }

        &:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
          background: #739d44;
        }

        &.mat-checkbox-checked.mat-accent {
          .mat-checkbox-background {
            background: #114267;

            .mat-checkbox-checkmark {
              fill  : #fafafa;
              width : 22px !important;
              margin: auto;
            }
          }

          &:before {
            opacity: 1 !important;
          }

          &:after {
            border-color    : #114267 !important;
            background-color: #f7fcff !important;
            box-shadow      : 0 0 4px 5px rgba(178, 191, 171, 0.14);
          }

          .mat-checkbox-inner-container {
            border-color: #fff;
            box-shadow  : 0 3px 10px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }

  .formContainer.halfFields {
    mat-checkbox {
      >label {
        white-space: normal;
      }
    }
  }
}

app-business-unit .mat-horizontal-content-container {
  display: none !important;
}

/*Process wizard material v 7.2.0*/
/*
.mat-horizontal-stepper-header{
    &[ng-reflect-state=edit]:after, &[ng-reflect-selected=true]:before, &[ng-reflect-state=edit]:before, &[ng-reflect-selected=true][ng-reflect-state=edit]:after{
        border-color: #739d44;
    }
}
.mat-stepper-horizontal-line{
    display: none;
}
.mat-step-header{
    &:hover{
        background-color: transparent !important;
    }
    .mat-ripple{
        display: none !important;
    }
    .mat-step-icon {
        background-color: #DADDD4;
        color: #DADDD4;
        border: 2px solid #DADDD4;
        height: 12px;
        width: 12px;
        &.mat-step-icon-selected{
            background-color: #fff;
            color: rgba(255,255,255,0);
            border: 2px solid #739d44;
            font-size: 0;
        }
        &.mat-step-icon-state-edit{
            background-color: #739d44;
            color: rgba(255,255,255,0);
            border: 2px solid #739d44;
        }
    }
    .mat-step-label {
        font-family: 'sf_ui_displaysemibold';
        font-size: 14px;
        color: #4a4a4a !important;
        margin-top: -60px;
        &.mat-step-label-selected{
            color: #739d44 !important;
        }
    }
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before
{
    top: 32px !important;
    width: calc(50% - 8px) !important;
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    top: 32px !important;
}
.RegistrationPanel{
    background-color: #ffffff;
    overflow: hidden;
    margin: 0 auto;
    padding: 60px 0 0 0;
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container{
        width: 50%;
    }
    .iconicCheckBoxGroup{
        margin-top: 110px;
        margin-bottom: 40px;
        .iconicCheckBoxContent{
            .iconicCheckBox{
                label.mat-checkbox-layout{
                    text-align: center;
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    span.mat-checkbox-label{
                        display: block;
                        position: relative;
                        top: -90px;
                        font-size: 18px;
                        font-family: 'sf_ui_displaymedium';
                        color: #2a3037;
                    }
                    .mat-checkbox-inner-container {
                        display: block;
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        background-color: #fff;
                        border:1px solid #739d44;
                        top: -15px;
                        margin: auto;
                        .mat-checkbox-frame{
                            opacity: 0;
                        }
                        .mat-checkbox-background{
                            border-radius: 100%;
                        }
                        .mat-checkbox-ripple{
                            .mat-ripple-element{
                                border-radius: 100%;
                            }
                            .mat-checkbox-background, .mat-checkbox-frame{
                                border-radius: 100%;
                                border: 1px solid #739d44;
                            }
                        }
                    }
                }
                &:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
                    background: #739d44;

                }
                &.mat-checkbox-checked.mat-accent{
                    .mat-checkbox-background{
                        background: #114267;
                        .mat-checkbox-checkmark {
                            fill: #fafafa;
                            width: 22px !important;
                            margin: auto;
                        }
                    }
                    &:before{
                        opacity: 1 !important;
                    }
                    &:after{
                        border-color: #114267 !important;
                        background-color: #f7fcff !important;
                        box-shadow: 0 0 4px 5px rgba(178, 191, 171, 0.14);
                    }
                    .mat-checkbox-inner-container{
                        border-color: #fff;
                        box-shadow: 0 3px 10px rgba(0,0,0,.2);
                    }
                }
            }
        }
    }
}
app-business-unit .mat-horizontal-content-container{
    display: none !important;
}
*/
/*File Upload*/
.upload-box {
  .upload-box-content {
    .drop-container {
      padding        : 30px;
      border         : 2px dotted #9b9b9b;
      display        : flex;
      text-align     : center;
      align-items    : center;
      justify-content: center;
      margin-left    : 30px;

      .upload-button {
        border        : 1px solid #739d44;
        border-radius : 3px;
        display       : block;
        width         : 80px;
        height        : 35px;
        line-height   : 35px;
        margin        : 10px auto;
        font-size     : 14px;
        color         : #4a4a4a;
        font-weight   : bold;
        text-transform: capitalize;
        cursor        : pointer;

        &:hover {
          background-color: #739d44;
          color           : #fff;
        }

        input {
          display: none;
        }
      }

      p {
        font-size  : 14px;
        font-family: "sf_ui_displaysemibold";

        >label {
          display: block;
        }
      }
    }

    .upload-item {
      font-size    : 12px;
      padding-left : 30px;
      position     : relative;
      margin-bottom: 30px;

      .filename {
        img {
          display : block;
          width   : 21px;
          position: absolute;
          left    : 0;
        }

        span {
          font-size    : 14px;
          font-family  : "sf_ui_displaysemibold";
          color        : #4a4a4a;
          width        : 100%;
          white-space  : nowrap;
          text-overflow: ellipsis;
          overflow     : hidden;
          display      : block;
        }
      }

      .progress-content {
        width        : 100%;
        height       : 5px;
        background   : #daddd4;
        display      : block;
        border-radius: 50px;
        overflow     : hidden;

        .bar {
          background: #216aa1;
          width     : 100%;
          height    : 5px;
          display   : block;
        }
      }

      .progress-text-content {
        .progress-text {
          font-size : 12px;
          color     : #9b9b9b;
          text-align: right;
          width     : 100%;
          display   : block;
        }
      }

      .upload-item-content {
        padding-right: 10px;

        .btnRemove {
          position   : absolute;
          right      : 0;
          top        : 0;
          padding    : 0;
          margin     : 0;
          max-width  : 0;
          min-width  : auto;
          color      : #9b9b9b;
          font-weight: bolder;
          margin-top : 5px;
        }
      }
    }
  }
}

.mat-dialog-container {
  border-radius: 4px !important;
  color        : #2a3037 !important;

  .mat-dialog-actions {
    padding      : 25px 0 0 0;
    margin-bottom: 0;
  }

  .RegistrationPanel {
    width: auto !important;
  }
}

/*POPUP*/
.popupHead {
  position: relative;

  button {
    position    : absolute;
    top         : 0;
    right       : 0;
    font-size   : 18px;
    margin-right: -5px;
    border      : 0;
    padding     : 13px;
    cursor      : pointer;

    &:focus {
      outline: none;
    }

    &:before,
    &:after {
      content   : "";
      width     : 2px;
      height    : 15px;
      background: #2a3037;
      position  : absolute;
      display   : block;
      top       : 0;
      margin-top: 5px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.mat-dialog-container {
  border-radius: 8px !important;
  color        : #2a3037 !important;

  .mat-dialog-actions {
    padding      : 25px 0 0 0;
    margin-bottom: 0;

    button {
      margin-left: 0 !important;
    }
  }
}

// CSS by Harsh
.dropped-po-wrapper {
  margin: 20px;
}

.draggble-po-wrapper {
  ul {
    padding: 0px;
    margin : 0px;

    li {
      list-style: none;
    }
  }
}

.row-total {
  border-top      : 1px solid #f1f0f0;
  border-bottom   : 1px solid #f1f0f0;
  background-color: #fafafa;

  ul {
    li {
      font-size  : 13px;
      border-left: 1px solid #f1f0f0;
      display    : inline-block;
      padding    : 7px 15px;

      &:first-child {
        border-left: 0 none;
        width      : calc(150px - 28px);
      }

      span {
        font-family: "sf_ui_displaybold";
      }
    }
  }

  .total-items {
    padding: 4px 80px 0px 0px;
  }
}

.mat-chip.mat-standard-chip {
  background-color: transparent;
  font-size       : 12px;
  border          : 1px solid #e3e2e2;
  font-family     : "sf_ui_displaybold";

  i {
    margin-left: 10px;
    cursor     : pointer;
  }
}

.load-details {
  padding: 20px 20px 20px 5px;
}

.self-modify,
.statusTag.self-modify {
  position     : relative;
  border       : 1px solid #e9670e;
  border-radius: 13px;
  font-size    : 12px;
  padding      : 3px 10px;
  font-weight  : bolder;

  /* &:after {
    content: "";
    width: 7px;
    height: 8px;
    background: #2a8fcb;
    display: block;
    border-radius: 50%;
    right: -12%;
    top: 50%;
    position: absolute;
    margin-top: -4px;
  } */
  &.modifiedTag {
    border: 1px solid #e9670e;
  }

  &.modifiedLabel {
    border: 1px solid #2a8fcb;
  }
}

.self-modify-dot,
.statusTag.self-modify-dot {
  background    : #2a8fcb;
  border-radius : 50%;
  width         : 8px;
  height        : 8px;
  display       : inline-block;
  border-radius : 50%;
  vertical-align: middle;
  margin-left   : 10px;
  margin-right  : 15px;
}

.statusTag {
  border-radius: 13px;
  font-size    : 12px;
  padding      : 3px 10px;
  font-weight  : bolder;

  &.modifiedTag {
    border: 1px solid #e9670e;
  }
}

.others-modify,
.statusTag.others-modify {
  position     : relative;
  border       : 1px solid #e9670e;
  border-radius: 13px;
  font-size    : 12px;
  padding      : 3px 10px;
  font-weight  : bolder;

  /*  &:after {
    content: "";
    width: 7px;
    height: 8px;
    background: #e9670f;
    display: block;
    border-radius: 50%;
    right: -12%;
    top: 50%;
    position: absolute;
    margin-top: -4px;
  } */
  &.modifiedTag {
    border: 1px solid #e9670e;
  }

  &.modifiedLabel {
    border: 1px solid #e9670e;
  }
}

.others-modify-dot,
.statusTag.others-modify-dot {
  background    : #e9670f;
  border-radius : 50%;
  width         : 8px;
  height        : 8px;
  display       : inline-block;
  border-radius : 50%;
  vertical-align: middle;
  margin-left   : 10px;
  margin-right  : 15px;
}

.statusTag.self-modify,
.statusTag.others-modify {
  border       : 0 !important;
  padding-right: 25px !important;
}

@media (max-width: 1279px) {
  .global-action {
    transform: scale(0.7);
    bottom   : 0 !important;
    right    : 0 !important;
  }

  .readOnlyField .w-180 {
    width: 100px;
  }

  .infoBlocks .readOnlyField .mat-form-field-infix {
    width: 100px !important;
  }

  .pageInfo {
    .mat-form-field-infix {
      width: 150px;
    }

    .w-180 {
      width: 150px;
    }
  }

  .readOnlyField {
    .w-180 {
      width: 100px;
    }
  }

  .linkBtn mat-button-toggle button {
    font-size: 14px;
  }

  .processStatus li {
    font-size    : 14px !important;
    margin-bottom: 15px !important;
  }

  .settingsCards .basicCards {
    padding: 10px !important;
  }

  .settingsCards .btn {
    font-size  : 12px !important;
    white-space: normal !important;
  }

  .mat-radio-label-content {
    font-size: 14px;
  }

  .dropped-po-wrapper .freight-charge .add-freight-charge {
    position: absolute;
    right   : -5px;
    top     : 4px;
    z-index : 1;
  }

  //   .infoBlocks{
  //   .fieldGroup{
  //     display: block;
  //     &.horizontalGroup{
  //       display: flex;
  //       align-items: baseline;
  //       .dateRange{
  //         display: flex;
  //         align-items: baseline;
  //       }
  //       .fieldLabel{
  //         white-space: nowrap;
  //       }
  //     }
  //     .horizontalGroup.dateRange{
  //       display: flex;
  //       align-items: baseline;
  //     }
  //   }
  //   .mt--30.mat-expansion-panel{
  //     margin-top: inherit !important;
  //   }
  // }
}

@media (max-width: 1600px) {
  .linkBtn mat-button-toggle button {
    font-size: 16px;
  }

  input {
    font-size: 14px;
  }

  .fs-24 {
    font-size: 20px !important;
  }

  .w-100per .mat-form-field-infix {
    width: 180px;
  }

  .infoBlocks .readOnlyField,
  .infoBlocks .formControl {
    margin-right : 15px;
    padding-right: 15px;
  }

  .basicCards {
    border-radius: 3px !important;
    box-shadow   : 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;

    &.supplierProfileImg {
      width : 100px;
      height: 60px;

      img {
        height: 100%;
      }
    }
  }

  .pdr-50 {
    padding-right: 20px;
  }

  .pdl-50 {
    padding-left: 20px;
  }

  .productCard {
    width: 100%;
  }

  .mat-cell,
  .mat-header-cell,
  .mat-form-field,
  .divTableHead,
  .divTableCell,
  .customTable tr th,
  .customTable tr td {
    font-size: 12px !important;
  }

  .userName[_ngcontent-c2] {
    font-size: 13px;
  }

  // .mat-nav-list .mat-list-item .mat-list-item-content > span {
  //   font-size: 16px;
  // }
  // .mat-nav-list {
  //   .mat-list-item {
  //     height: 40px !important;
  //     .mat-list-item-content {
  //       padding: 0 15px !important;
  //     }
  //   }
  // }
  .contentWrapper {
    padding: 65px 0 20px 50px;
  }

  .table .mat-checkbox-inner-container {
    width : 15px;
    height: 15px;
  }

  .readOnlyField {

    .fieldLabel,
    .fieldValue {
      font-size: 12px;
    }
  }

  .CollapseBtn {
    font-size: 14px;
  }

  .fs-16 {
    font-size: 14px !important;
  }

  .fs-14 {
    font-size: 12px !important;
  }

  .fs-18 {
    font-size: 16px !important;
  }

  .readOnlyField .fieldGroup {
    margin-bottom: 5px;
  }

  .dateRange .mat-icon {
    margin-top  : -8px;
    margin-right: 4px;
  }

  .filterLinkBtn mat-button-toggle button {
    font-size: 16px;
  }

  .tdHighlight span {
    font-size: 20px;
  }

  .table .mat-footer-cell {
    font-size: 14px;
  }

  .btn {
    font-size: 12px !important;
  }

  .customList .customItems>.reminderText .statusTag {
    margin-right : 5px;
    font-size    : 10px !important;
    padding      : 2px 8px !important;
    margin-bottom: 5px !important;
  }

  .infoBlocks .readOnlyField,
  .infoBlocks .formControl {
    margin-right: 10px;
  }

  .dateRange .mat-icon span {
    font-size: 16px !important;
  }

  .fs-20 {
    font-size: 18px !important;
  }

  .infoBlocks .readOnlyField,
  .infoBlocks .formControl {
    margin-right : 15px;
    padding-right: 15px;
  }

  .mat-select-arrow:before {
    border-width: 5px;
  }

  .mat-select-arrow:after {
    border-width: 5px;
  }

  .table {
    .mat-column-actions {
      width: 12%;
    }
  }

  .commentContent {

    .receiverContent,
    .senderContent {
      padding: 10px;
      width  : 87%;

      .paragraph {
        font-size : 16px;
        margin-top: 8px;
      }
    }
  }

  .msgBox {
    .msgBoxMsg {
      width        : 300px;
      text-overflow: ellipsis;
      overflow     : hidden;
      white-space  : nowrap;
    }
  }

  .headerWithIcon {
    margin-bottom: 15px;
  }

  .RegistrationPanel .iconicCheckBoxGroup .iconicCheckBoxContent {
    margin-right: 60px !important;
  }

  .pdlr-60 {
    padding-left : 20px !important;
    padding-right: 20px !important;
  }

  .processStatus li {
    font-size    : 14px !important;
    margin-bottom: 15px !important;
  }
}

@media (min-width: 1601px) {
  .msgBox {
    .msgBoxMsg {
      width        : 700px;
      text-overflow: ellipsis;
      overflow     : hidden;
      white-space  : nowrap;
    }
  }
}

@media (min-width: 1281px) and (max-width: 1600px) {
  .infoBlocks .readOnlyField .mat-form-field-infix {
    width: 180px !important;
  }

  .w-180 {
    width: 180px;
  }
}

/* For zoom level */
// @media (-webkit-min-device-pixel-ratio: 1.05) {

//   }

// Add freight dailogue
.apply-by {
  label {
    display: block;
  }
}

.add-breakup {
  color      : #739d44;
  font-family: "sf_ui_displaybold";
  font-size  : 14px;
  cursor     : pointer;
}

.breakup-list {
  button {
    &.roundBtn {
      padding      : 0 !important;
      width        : 30px;
      height       : 30px;
      min-width    : 30px !important;
      border-radius: 50px !important;
      margin-left  : 15px;
    }
  }
}

.total-freight-charge {
  font-family     : "sf_ui_displaybold";
  background-color: #f9f9f9;
  border-top      : 1px solid #eeeeee;
  border-bottom   : 1px solid #eeeeee;
  margin          : 30px -24px;
  padding         : 6px 24px;
}

.global-action {
  position  : fixed;
  bottom    : 20px;
  right     : 20px;
  z-index   : 99;
  width     : 100%;
  text-align: right;

  button {
    width           : 60px;
    max-width       : 60px;
    height          : 60px;
    display         : inline-block;
    border          : 1px solid #43a40f;
    border-radius   : 50%;
    text-align      : center;
    background-color: #ffffff;
    box-shadow      : 0px 1px 7px rgba(67, 164, 15, 0.6);
    cursor          : pointer;

    &:disabled {
      background-color: #f3f3f3 !important;
      border-color    : rgba(0, 0, 0, 0.26);
      box-shadow      : 0 1px 7px rgba(0, 0, 0, 0.26);

      i {
        &::before {
          color: rgba(0, 0, 0, 0.26);
        }
      }
    }

    &:focus {
      outline: 0 none;
    }

    // &:hover {
    //     i {
    //         &::before {
    //             color: #43a40f;
    //         }
    //     }
    // }
    i {
      font-size  : 24px;
      line-height: 60px;

      &::before {
        color: #4a4a4a;
      }
    }

    +button {
      margin-left: 20px;
    }
  }
}

.create-watchlist-form {
  display       : flex;
  flex-direction: column;

  >* {
    width: 100%;
  }

  .date-info,
  .routing-info {
    >label {
      font-family  : "sf_ui_displayregular";
      font-size    : 12px;
      color        : #4a4a4a;
      margin-bottom: 15px;
      display      : inline-block;
    }

    .mat-radio-button {
      margin-bottom: 10px !important;

      .mat-radio-label {
        font-size: 14px;
        color    : #4a4a4a;
      }
    }
  }

  .date-info {
    margin-left: 30px;
  }
}

.info-field {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .informative-label {
    margin-left: 58px;
    margin-top : -5px;

    .fieldLabel {
      font-size: 12px;
      color    : #777777;
    }
  }

  .informative-biglabel {
    margin-left: 76px;
    margin-top : -5px;

    .fieldLabel {
      font-size: 12px;
      color    : #777777;
    }
  }

  .informative-smlabel {
    margin-left: 46px;
    margin-top : -5px;

    .fieldLabel {
      font-size: 12px;
      color    : #777777;
    }
  }
}

.disabled-Tr {
  background: rgba(0, 0, 0, 0.03);
  opacity   : 0.5;
}

/* Depricated Material CSS For Specific Custom Class */
.copy-order {
  .mat-form-field {
    .mat-form-field-wrapper {
      ::ng-deep .mat-form-field-underline {
        display: none !important;
      }
    }
  }

  ::ng-deep .mat-form-field-subscript-wrapper {
    margin-top: -3px !important;
  }
}

.sticky-actions {
  position        : fixed;
  bottom          : 0;
  left            : 0;
  right           : 0;
  padding         : 10px 15px;
  text-align      : right;
  background-color: #ffffff;
  border-top      : solid 1px #cccccc;
  border-bottom   : solid 1px #cccccc;
  z-index         : 1;
}

.claim-tale {
  .mat-form-field-subscript-wrapper {
    display: contents;
  }

  .mat-form-field {
    .mat-form-field-infix {
      width: unset !important;
    }
  }

  .mat-form-field-flex {
    align-items: center;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin            : 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin            : 0;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
  width     : 12.5em;
  box-shadow: 0 4px 8px -1px rgba(0, 0, 0, 0.09);
  border    : 1px solid rgba(0, 0, 0, 0.04);

  .owl-dt-container-buttons {
    justify-content: flex-end;
    padding        : 0 15px;

    .owl-dt-control {
      width      : auto;
      font-size  : 12px;
      font-family: "sf_ui_displaysemibold";

      &:first-child {
        margin-right: 10px;
        color       : #4a4a4a;
      }

      &:last-child {
        color: #739d44;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  .owl-dt-timer-box {
    width: 100%;
  }

  .owl-dt-timer-content .owl-dt-timer-input {
    border-color: #dddddd;
  }
}

// In the button structure Angular Material builds various sub components and the last one is a 'div' with css class mat-button-focus-overlay
// Overwrite classes 'cdk-focused' and 'cdk-program-focused'
.mat-button-focus-overlay {
  background-color: transparent !important;
}

//Demand Creat order Page
.create-order-cards.swiper-pos {
  width: 100%;

  .swiper-container {
    background    : #f5f7fa;
    padding-bottom: 10px;
    margin        : 15px 0 0;
    box-shadow    : inset 0 0px 2px 2px #e1e2e4;
    margin        : 0px 0px !important;
    position      : unset !important;
    width         : 100% !important;

    .swiper-wrapper {
      .swiper-slide {
        width       : 870px !important;
        min-height  : 300px !important;
        margin-right: 30px !important;

        .card-counter {
          font-size  : 12px;
          color      : #c2c9d2;
          font-weight: 400;
          position   : absolute;
          top        : -17px;
        }

        .formContainer {
          .greenColorIcon {
            transform        : rotate(45deg);
            -moz-transform   : rotate(45deg);
            -webkit-transform: rotate(45deg);
            display          : block;
            right            : 20px;
            position         : absolute;
            cursor           : pointer;
            width            : 20px;
            height           : 20px;

            .plusBtn {
              cursor: pointer;
            }
          }

          mat-form-field {
            &.mat-form-field-type-mat-select {
              width: 140px !important;
            }

            &.mat-form-field-type-mat-input {
              width: 140px !important;
            }
          }

          .abc {
            margin-right: 15px !important;
          }

          .form-head-section:last-child {
            mat-form-field {
              &.mat-form-field-type-mat-select {
                width: 210px !important;
              }
            }
          }

          .linkBtn mat-button-toggle {
            margin-right: 15px !important;
          }
        }

        .responsiveTable {
          height: 50vh;

          table {
            // height: 100%;
          }

          tr {
            th {
              border-top   : 0 !important;
              border-bottom: 0 !important;
              position     : relative;
              background   : #fff;
              position     : sticky;
              top          : 0;
              z-index      : 1;

              &:before {
                content   : "";
                width     : 100%;
                height    : 1px;
                background: #739d44;
                position  : absolute;
                display   : block;
                left      : 0;
                top       : 0;
              }

              &:after {
                content   : "";
                width     : 100%;
                height    : 1px;
                background: #739d44;
                position  : absolute;
                display   : block;
                left      : 0;
                bottom    : 0;
              }
            }
          }

          tfoot {
            position: sticky;
            bottom  : 0;

            tr td {
              background: #fff;
              box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.1);
              position  : sticky;
              bottom    : 0;
            }
          }
        }
      }
    }
  }
}

table {
  tr {
    td {
      input.td-input {
        font-size    : 14px;
        border       : none;
        display      : inline-block;
        min-width    : 80px;
        max-width    : 100px;
        height       : 40px;
        padding      : 0px 5px;
        border       : 1px solid #e3e2e2;
        border-radius: 4px;
      }
    }
  }
}

.baselineAlignment {
  align-items: baseline !important;
}

.pdr-35 {
  padding-right: 35px;
}

.td-input-datepicker {
  form {
    width: 125px;
  }

  mat-form-field {
    .mat-form-field-flex {
      align-items: center;
      width      : 100%;
    }
  }
}

.td-input-error {
  form {
    mat-form-field {
      .mat-form-field-flex {
        input {
          color: #D50000;
        }
      }
    }
  }
}

.input-date-error {
  form {
    mat-form-field {
      .mat-form-field-flex {
        .mat-form-field-infix {
          input {
            color: #D50000 !important;
          }
        }
      }
    }
  }
}

.custome-readonly-block {
  .mat-select-disabled .mat-select-value {
    color: black;
  }

  .mat-select-arrow {
    visibility: hidden;
  }
}



.range-error {
  font-family: "sf_ui_displaybold";
  font-size  : 12px !important;
  font-weight: 400;
  line-height: 1.125;
  color      : #f44336;
}

.loading {
  font-family: "sf_ui_displaybold";
  font-size  : 12px !important;
  font-weight: 400;
  line-height: 1.125;
  color      : black;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #092541 !important;
}

.pricingInfo {
  background-color: #f9f9f9;

  mat-tab-header {
    background-color: transparent;
    margin          : 0px !important;
  }
}

.mt-30 {
  margin-top: 30px;
}

.w-300 {
  width: 300px;
}

.add-products {
  text-align     : center;
  display        : flex;
  align-items    : center;
  justify-content: center;
  height         : 100%;
  padding-top    : 40px;

  i {
    display    : block;
    color      : #e7e6e6;
    font-size  : 90px;
    line-height: 90px;
  }

  .no-products {
    font-size    : 14px;
    display      : block;
    font-weight  : bold;
    color        : #9b9b9b;
    margin-bottom: 20px;
  }
}

.border-bottom {
  border-bottom: 1px solid #eeeeee;
}

.pdtb-20 {
  padding: 20px 0px;
}

.selected-product {
  position: relative;

  h4 {
    font-size : 14px;
    color     : #4b4b4b;
    margin-top: 0px;
    margin    : 0px 0px 10px 0px;
  }

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .products-button {
    position  : absolute;
    bottom    : 0;
    right     : 0;
    width     : 100%;
    background: #fff;
    box-shadow: 0 -2px 4px 0 #d4dbe2;
    padding   : 15px;
  }
}

.list-unstyled {
  padding   : 0px;
  list-style: none;
  margin-top: 0px;
}

.edit-share-icon {
  position: absolute;
  right   : -10px;
  top     : 6px;
}

.roundBtn {
  padding      : 0 !important;
  width        : 40px;
  height       : 40px;
  min-width    : 40px !important;
  border-radius: 50px !important;

  &:disabled {
    filter : grayscale(1);
    opacity: 0.7;
  }

  .icons-alldocs,
  .icons-preview,
  .icons-reprocess {
    color: #739d44;
  }
}

.overflowX-hidden {
  overflow-x: hidden;
}

.divTable {
  display        : table;
  width          : 100%;
  border-collapse: collapse;

  .w-2-per {
    width: 2%;
  }

  .w-4-per {
    width: 4%;
  }

  .w-20-per {
    width: 20%;
  }

  .w-54-per {
    width: 54%;
  }

  .w-50-px {
    width     : 50px;
    max-width : auto;
    margin-top: 1px;
  }
}

.textWidth {
  width: 50% !important;
}

.divTableRow {
  display: table-row;
}

.divTableHeading {
  background-color: #eee;
  display         : table-header-group;
}

/* .divTableCell{
    border: 1px solid #e3e2e2;
    border-top:0;
    display: table-cell;
    font-size: 14px;
    padding: 8px 10px;
    vertical-align:middle;
    input.td-input-full{
        font-size: 14px;
        border: none;
        display: inline-block;
        max-width: 50%;
        height: 40px;
        padding: 0 5px;
        border: 1px solid #e3e2e2;
        border-radius: 4px;
      }
} */

.divTableCell {
  border    : 1px solid #e3e2e2;
  border-top: 0;
  display   : table-cell;
  font-size : 14px;
  padding   : 3px 10px 3px 10px;

  input.td-input-full {
    font-size    : 14px;
    border       : none;
    display      : inline-block;
    max-width    : 100%;
    height       : 25px;
    padding      : 0 5px;
    border       : 1px solid #e3e2e2;
    border-radius: 4px;
  }

  .btn {
    padding: 3px 15px !important;
    margin : 7px 0 !important;
  }

  .roundBtn {
    line-height: 30px;
    height     : 30px;

    .icon-cancel:before {
      content    : "\e902";
      display    : block;
      width      : 15px;
      height     : 15px;
      line-height: 15px;
      margin-top : 6px;
    }
  }
}

.divTableHead {
  // border-top: 1px solid #739d44;
  // border-bottom: 1px solid #739d44;
  border-left : 1px solid #e3e2e2;
  border-right: 1px solid #e3e2e2;
  display     : table-cell;
  padding     : 8px 10px;
  color       : #4b4b4b;
  font-size   : 14px;
  font-family : "sf_ui_displaysemibold";
  position    : sticky;
  // top:65px;
  top         : 0px;
  background  : #fff;
  z-index     : 1;

  &.small-size {
    width: 20px;
  }

  &:before {
    content   : "";
    width     : 100%;
    top       : 0;
    height    : 1px;
    background: #739d44;
    display   : block;
    position  : absolute;
    left      : 0;
  }

  &:after {
    content   : "";
    width     : 100%;
    bottom    : 0;
    height    : 1px;
    background: #739d44;
    display   : block;
    position  : absolute;
    left      : 0;
  }
}

.divTableHeading {
  background-color: #fff;
  display         : table-header-group;
  font-weight     : bold;
}

.divTableFoot {
  background-color: #eee;
  display         : table-footer-group;
  font-weight     : bold;
}

.divTableBody {
  display: table-row-group;
}

.divTemplateList {
  height    : 400px;
  overflow-y: auto;
  padding   : 10px;
}

.divTemplateList::-webkit-scrollbar {
  width: 10px;
}

.divTemplateList::-webkit-scrollbar-track {
  box-shadow   : inset 0 0 5px grey;
  border-radius: 10px;
}

.divTemplateList::-webkit-scrollbar-thumb {
  background   : grey;
  border-radius: 10px;
}

.greenColor {

  &.icons-share:before,
  &.icons-copy:before {
    color: #739d44;
  }
}

table {
  tr {
    td {
      input.td-input-full {
        font-size    : 14px;
        border       : none;
        display      : inline-block;
        max-width    : 100%;
        height       : 40px;
        padding      : 0 5px;
        border       : 1px solid #e3e2e2;
        border-radius: 4px;
      }
    }

    &.closeWithPadding {
      td {
        table.element-details {
          height  : 0;
          overflow: hidden;
          display : none;
        }
      }
    }
  }
}

table.element-details {
  border-collapse: collapse;
  table-layout   : fixed;
}

.table-layout-fixed {
  table-layout: fixed;
}

.collapsibleTr {
  background-color: #e4e9f0;
}

.fixedData.left-aligned {
  >div {
    justify-content: start !important;

    >span {
      margin-right: 10px;
    }
  }
}

// SVG font icons starts here
/*************************** Pricing sheet Creation********************************************** */
//******************* new css*******************//

// SVG font icons starts here
@font-face {
  font-family: "procurant";
  src        : url("assets/fonts/procurant.eot?16538401");
  src        : url("assets/fonts/procurant.eot?16538401#iefix") format("embedded-opentype"),
    url("assets/fonts/procurant.woff2?16538401") format("woff2"),
    url("assets/fonts/procurant.woff?16538401") format("woff"),
    url("assets/fonts/procurant.ttf?16538401") format("truetype"),
    url("assets/fonts/procurant.svg?16538401#procurant") format("svg");
  font-weight: normal;
  font-style : normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'procurant';
    src: url('../font/procurant.svg?46989818#procurant') format('svg');
  }
}
*/

[class^="icons-"]:before,
[class*=" icons-"]:before {
  font-family            : "procurant";
  font-style             : normal;
  font-weight            : normal;
  speak                  : none;
  display                : inline-block;
  text-decoration        : inherit;
  width                  : 1em;
  text-align             : center;
  font-variant           : normal;
  text-transform         : none;
  line-height            : 1em;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icons-reprocess:before {
  content: "\e800";
}

/* '' */
.icons-preview:before {
  content: "\e801";
}

/* '' */
.icons-outbound:before {
  content: "\e802";
}

/* '' */
.icons-inbound:before {
  content: "\e803";
}

/* '' */
.icons-alldocs:before {
  content: "\e804";
}

/* '' */
.icons-documentmonitor:before {
  content: "\e805";
}

/* '' */
.icons-share:before {
  content: "\e807";
  color  : #739d44;
}

/* '' */
.icons-copy:before {
  content: "\e808";
  color  : #739d44;
}

/* '' */
.icons-icon_noitems:before {
  content: "\e809";
}

/* '' */
.icons-download:before {
  content: "\e812";
}

/* '' */

// SVG font icons ends here

.withGreyBg {
  background-color: #f9f9f9;
}

.tags-container {
  .mat-chip.mat-standard-chip {
    margin          : 5px 10px 5px 0px;
    padding         : 13px 0px 13px 10px !important;
    color           : #4a4a4a;
    font-size       : 11px;
    font-weight     : 500;
    border          : 1px solid #d8d8d8;
    background-color: #fff;
    min-height      : 20px;

    .plusBtn {
      cursor     : pointer;
      position   : relative;
      height     : 18px;
      display    : block;
      padding-top: 17px;
      transform  : rotate(45deg);

      &:before {
        content      : "";
        width        : 100%;
        height       : 2px;
        background   : #739d44;
        display      : block;
        margin       : auto;
        margin-bottom: -2px;
      }

      &:after {
        content          : "";
        width            : 13px;
        height           : 2px;
        background       : #739d44;
        display          : block;
        -webkit-transform: rotate(270deg);
        transform        : rotate(270deg);
        margin           : auto;
      }
    }

    .close-chip {
      display        : inline-block;
      padding-right  : 15px;
      opacity        : 0.5;
      text-decoration: none;
      color          : #739d44;
      font-size      : 16px;
    }
  }

  .mat-chip-list-wrapper {
    margin   : 0;
    // margin: 0 0 10px;
  }

  .mat-form-field-infix {
    padding-bottom: 0 !important;
  }
}

.border-0 {
  border: 0 !important;
}

.custom-label {
  font-size  : 14px;
  font-family: "sf_ui_displayregular";
  color      : #4a4a4a;
  display    : block;
}

.list-with-action {
  padding: 0;

  li {
    margin-bottom: 0;

    div {
      display    : flex;
      align-items: center;

      >span:first-child {
        margin-right: 10px;
      }

      button {
        margin-left: auto;
        cursor     : pointer;
      }
    }
  }
}

.h-55vh {
  height: 55vh;
}

.h-55vh::-webkit-scrollbar {
  width: 10px;
}

.h-55vh::-webkit-scrollbar-track {
  box-shadow   : inset 0 0 5px grey;
  border-radius: 10px;
}

.h-55vh::-webkit-scrollbar-thumb {
  background   : grey;
  border-radius: 10px;
}

.h-60vh {
  height: 60vh;
}

.h-60vh::-webkit-scrollbar {
  width: 10px;
}

.h-60vh::-webkit-scrollbar-track {
  box-shadow   : inset 0 0 5px grey;
  border-radius: 10px;
}

.h-60vh::-webkit-scrollbar-thumb {
  background   : grey;
  border-radius: 10px;
}

.auto-overflow {
  overflow: auto;
}

.mlr--24 {
  margin-left : -24px;
  margin-right: -24px;
}

th.mat-table-sticky {
  &:before {
    content   : "";
    width     : 100%;
    top       : -1px;
    height    : 1px;
    background: #739d44;
    display   : block;
    position  : absolute;
    left      : 0;
  }

  &:after {
    content   : "";
    width     : 100%;
    bottom    : 0;
    height    : 1px;
    background: #739d44;
    display   : block;
    position  : absolute;
    left      : 0;
  }
}

.campiarIndicator {
  background: rgba(0, 0, 0, 0.04);
}

// ::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
// }

// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb {
//   background: grey;
//   border-radius: 5px;
// }
// new scroll design
::-webkit-scrollbar {
  width : 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background   : #d9d9d9;
  border-radius: 4px;
}

.fixed-table {
  td {
    .truncated-text {
      width: auto;
    }
  }
}

//Fixed header
.fixedHeaders {
  height        : 400px;
  padding-bottom: 15px;

  .element-details {
    th {
      top    : 90px;
      z-index: 1;
    }
  }

  .fixinTable th {
    position     : sticky;
    top          : 0;
    background   : #fff;
    z-index      : 2;
    border-top   : 0 !important;
    border-bottom: 0 !important;

    &:before {
      content   : "";
      width     : 100%;
      height    : 1px;
      background: #739d44;
      position  : absolute;
      display   : block;
      left      : 0;
      top       : 0;
    }

    &:after {
      content   : "";
      width     : 100%;
      height    : 1px;
      background: #739d44;
      position  : absolute;
      display   : block;
      left      : 0;
      bottom    : 0;
    }
  }

  // .grandTotal{
  //     position: sticky;
  //     bottom:0;
  // }
}

/*NEW UPDATES FOR RESPONSIVE VIEW*/
.pageInfo {
  .infoBlocks {
    .readOnlyField {
      &:last-child {
        margin : 0;
        padding: 0;
      }

      .horizontalGroup {
        display        : flex;
        justify-content: flex-start;
        align-items    : baseline;

        .dateRange,
        .freight-charge {
          display        : flex;
          justify-content: flex-start;
          align-items    : baseline;
          width          : 100%;
        }

        .fieldLabel {
          white-space: nowrap;
        }

        .fieldValue {
          white-space  : nowrap;
          text-overflow: ellipsis;
          overflow     : hidden;
          width        : auto;
        }

        .mat-form-field {
          display : block !important;
          // width: 100%;
        }

        .statusTag.self-modify,
        .statusTag.others-modify {
          padding-right: 0 !important;
        }
      }
    }

    .horizontalGroupWithoutLabel {
      .dateRange {
        display        : flex;
        justify-content: flex-start;
        align-items    : baseline;
        width          : 100%;

        >mat-form-field:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}

td {
  .fieldGroup {
    .horizontalGroup {
      display        : flex;
      justify-content: flex-start;
      align-items    : baseline;

      .dateRange {
        display        : flex;
        justify-content: flex-start;
        align-items    : baseline;
        width          : 100%;
      }

      .fieldLabel {
        white-space: nowrap;
      }

      .fieldValue {
        white-space  : nowrap;
        text-overflow: ellipsis;
        overflow     : hidden;
      }

      .mat-form-field {
        display : block !important;
        // width: 100%;
      }
    }
  }
}

td {

  // .element-details{
  .infoBlocks {
    .readOnlyField {
      &:last-child {
        margin : 0;
        padding: 0;
      }

      .fieldGroup {
        display        : flex;
        justify-content: flex-start;
        align-items    : baseline;

        .dateRange {
          display        : flex;
          justify-content: flex-start;
          align-items    : baseline;
          width          : 100%;
        }

        .fieldLabel {
          white-space: nowrap;
        }

        .fieldValue {
          white-space  : nowrap;
          text-overflow: ellipsis;
          overflow     : hidden;
        }

        .mat-form-field {
          display : block !important;
          // width: 100%;
        }
      }
    }
  }

  // }
}

.msgBox {
  .readOnlyField {
    &:last-child {
      margin : 0;
      padding: 0;
    }

    .fieldGroup {
      display        : flex;
      justify-content: flex-start;
      align-items    : baseline;
      max-width      : 30%;

      .dateRange {
        display        : flex;
        justify-content: flex-start;
        align-items    : baseline;
        width          : 100%;
      }

      .fieldLabel {
        white-space: nowrap;
      }

      .fieldValue {
        white-space  : nowrap;
        text-overflow: ellipsis;
        overflow     : hidden;
      }

      .mat-form-field {
        display : block !important;
        // width: 100%;
      }
    }
  }
}

.tableFilterPanel {
  .dateRange {
    display        : flex;
    justify-content: flex-start;
    align-items    : baseline;
  }
}

tfoot {
  .readOnlyField {
    .fieldGroup.horizontalGroup {
      display        : flex;
      justify-content: flex-start;
      align-items    : baseline;

      .fieldLabel {
        white-space: nowrap;
      }

      .fieldValue {
        white-space  : nowrap;
        text-overflow: ellipsis;
        overflow     : hidden;
      }
    }
  }
}

.removePadding {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.addPadding {
  .mat-form-field-wrapper {
    padding-bottom: 15px !important;
  }
}

.upload-doc-chips {
  .mat-chip-list-wrapper {
    justify-content: center !important;
    width          : 100%;
  }
}

.viewer {
  .iFrame {
    width : 100%;
    height: 80vh;

    html {
      body {
        img {
          width: 100% !important;
        }
      }
    }
  }
}

/* kendo */
.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-desc-sm,
.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-asc-sm {
  position: absolute !important;
  right   : 0px !important;
}

.k-sort-order {
  display: none !important;
}

.blur-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation        : text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.blur-out {
  -webkit-animation: text-blur-out 1.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation        : text-blur-out 1.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.text-trackin {
  -webkit-animation: tracking-in-contract-bck 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation        : tracking-in-contract-bck 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.text-trackout {
  -webkit-animation: tracking-out-expand-fwd 1.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation        : tracking-out-expand-fwd 1.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.task-center-click {
  color    : #739d44 !important;
  font-size: 15px !important;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter        : blur(12px);
    opacity       : 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter        : blur(0px);
    opacity       : 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter        : blur(12px);
    opacity       : 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter        : blur(0px);
    opacity       : 1;
  }
}

@-webkit-keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
    filter        : blur(0.01);
  }

  100% {
    -webkit-filter: blur(12px) opacity(0%);
    filter        : blur(12px) opacity(0%);
  }
}

@keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
    filter        : blur(0.01);
  }

  100% {
    -webkit-filter: blur(12px) opacity(0%);
    filter        : blur(12px) opacity(0%);
  }
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing   : 1em;
    -webkit-transform: translateZ(400px);
    transform        : translateZ(400px);
    opacity          : 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform        : translateZ(0);
    opacity          : 1;
  }
}

@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing   : 1em;
    -webkit-transform: translateZ(400px);
    transform        : translateZ(400px);
    opacity          : 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform        : translateZ(0);
    opacity          : 1;
  }
}

@-webkit-keyframes tracking-out-expand-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform        : translateZ(0);
    opacity          : 1;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    letter-spacing   : 1em;
    -webkit-transform: translateZ(300px);
    transform        : translateZ(300px);
    opacity          : 0;
  }
}

@keyframes tracking-out-expand-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform        : translateZ(0);
    opacity          : 1;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    letter-spacing   : 1em;
    -webkit-transform: translateZ(300px);
    transform        : translateZ(300px);
    opacity          : 0;
  }
}

// for copy PO
.copy-PO-DatePicker {
  date-picker {
    mat-form-field {
      z-index: 2 !important;
    }
  }
}

.mat-form-field-infix {
  input {
    height: 100%;
  }
}

.mat-cell,
.mat-header-cell,
.mat-form-field,
.divTableHead,
.divTableCell,
.customTable tr th,
.customTable tr td {
  font-size: 12px !important;
}

.k-notification-group {
  z-index: 999999999999999999 !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.cursor-poniter {
  cursor: pointer;
}

/* app-order-details-report{
    display: none;
} */

/* Suffix this section at the end of your styles.css for toogling between @media screen and @media print mode */
/*############################################################################################################*/

@media print {
  /* invert the display (show/hide) properties of the main */
  /* aplication component and the printing component       */

  header-toolbar {
    display: none !important;
  }

  /*  #sidenav-content-id{
    display: none !important; 
  } */
  #sidenav-content-id {
    margin: 0px !important;
  }

  mat-sidenav {
    display: none !important;
  }

  #orderDetailsNonPrintable {
    display: none !important;
  }

  app-order-details-report {
    display: block !important;
  }
}

/*#############################################################################################################*/
.custom-mat-check-all-checkbox {
  position        : sticky;
  padding-left    : 16px;
  padding-top     : 5px;
  padding-bottom  : 5px;
  background      : white;
  width           : auto;
  background-color: white;
  // top: 52px;
  z-index         : 1;
}

.filterAlert {
  &:after {
    content         : "";
    width           : 9px;
    height          : 9px;
    background-color: #f57f23;
    position        : absolute;
    border-radius   : 50px;
    right           : 0;
    margin-top      : 3px;
    margin-right    : 8px;
    border          : 2px solid #fff;
  }
}

//button loader spinner
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content         : "";
  box-sizing      : border-box;
  position        : absolute;
  top             : 50%;
  left            : 50%;
  width           : 20px;
  height          : 20px;
  margin-top      : -10px;
  margin-left     : -10px;
  border-radius   : 50%;
  border          : 3px solid transparent;
  border-top-color: #739d44;
  animation       : spinner 1s linear infinite;
}

.mat-icon {
  overflow: inherit !important;
}

.customClass {
  .mat-option-text {
    font-family: 'Nunito Sans' !important;
    font-style : normal !important;
    font-weight: 400 !important;
    font-size  : 14px !important;
    line-height: 19px !important;
    color      : #757575 !important;
  }
}

.mat-calendar {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20) !important;
}

.procurant-link-btn {

  display      : flex;
  padding      : 0px 16px;
  height       : 36px;
  align-items  : center;
  gap          : 8px;
  border-radius: 4px;
  background   : $overlayLeftSideBtn;
  border-color : transparent;

  span {
    color         : $overlayLeftSideBtnText;
    /* Buttons/Large */
    font-size     : 14px;
    font-family   : 'Nunito Sans' !important;
    font-style    : normal;
    font-weight   : 600;
    line-height   : normal;
    text-transform: capitalize;
    cursor        : pointer;
  }



}

.procurant-submit-btn {
  display      : flex;
  padding      : 8px 16px;
  height       : 38px;
  align-items  : center;
  gap          : 8px;
  border-radius: 4px;
  border       : $overlayRightSideBtnBorder;
  background   : $overlayRightSideBtn;

  &:disabled {
    border          : 1px solid $overlayRightSideBtnBorder !important;
    background-color: $overlayRightSideBtnBorder !important;
    box-shadow      : 2px 2px 6px 0px rgba(0, 0, 0, 0.15) !important;

    span {
      color: #FFF;
    }
  }

  span {
    color         : $overlayRightSideBtnText;
    font-size     : 14px;
    font-family   : 'Nunito Sans' !important;
    font-style    : normal;
    font-weight   : 600;
    line-height   : normal;
    text-transform: capitalize;
    cursor        : pointer;
  }

}

.procurant-green-btn {
  display      : flex;
  padding      : 0px 16px;
  height       : 36px;
  align-items  : center;
  gap          : 8px;
  border-radius: 4px;
  border       : $pagePrimaryBtnBorder;
  background   : $pagePrimaryBtn !important;
  box-shadow   : 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);

  &:disabled {
    border          : 1px solid $pagePrimaryBtnBorder !important;
    background-color: $pagePrimaryBtnBorder !important;
    box-shadow      : 2px 2px 6px 0px rgba(0, 0, 0, 0.15) !important;

    span {
      color: #FFF;
    }
  }

  span {
    //color: $overlayRightSideBtnText;
    font-size     : 14px;
    font-family   : 'Nunito Sans' !important;
    font-style    : normal;
    font-weight   : 600;
    line-height   : normal;
    text-transform: capitalize;
    cursor        : pointer;
  }
}

.procurant-white-link-btn {

  display      : flex;
  padding      : 0px 16px;
  height       : 36px;
  align-items  : center;
  gap          : 8px;
  border-radius: 4px;
  background   : $pageLinkBtn;
  border-color : transparent;

  span {
    color         : $pageLinkBtnText;
    /* Buttons/Large */
    font-size     : 14px;
    font-family   : 'Nunito Sans' !important;
    font-style    : normal;
    font-weight   : 600;
    line-height   : normal;
    text-transform: capitalize;
    cursor        : pointer;
  }

}

.grid {
  .tick {
    line {
      // color: #E0E0E0 !important;
      stroke           : #E0E0E0 !important;
      // stroke-opacity: 0.5;
      shape-rendering  : crispEdges;
    }

    text {
      font-size            : 14px !important;
      color                : var(--Color-Fonts-Primary, #212121);
      font-feature-settings: 'clig'off, 'liga'off;
      font-family          : "Nunito Sans";
      font-style           : normal;
      font-weight          : 600;
      line-height          : 20px;
    }
  }
}

.grid-line path {
  stroke-width: 0;
}

.label-multiple-bar {
  font-family : Verdana, Geneva, Tahoma, sans-serif !important;
  font-family : Nunito Sans !important;
  writing-mode: tb;
  font-style  : normal;
  line-height : normal;
}

.label-multiple-bar-less {
  font-size   : 12px !important;
  fill        : #EF6C00;
  writing-mode: tb;
}

.label-multiple-bar-greater {
  font-size   : 12px !important;
  fill        : #44BF25;
  writing-mode: tb;
}

.label-multiple-bar-equal {
  font-size   : 10px !important;
  fill        : #757575;
  writing-mode: tb;
}

div.graph-tooltip {
  color         : white;
  position      : absolute;
  text-align    : center;
  max-width     : 250px;
  max-height    : 28px;
  padding       : 2px;
  font-family   : Nunito Sans;
  font-size     : 12px;
  font-style    : normal;
  font-weight   : 600;
  line-height   : normal;
  border        : 0px;
  pointer-events: none;
  z-index       : 10000;
  border-radius : 2px;
  background    : #616161;
}

.pw-graph-tooltip {
  color        : white;
  position     : absolute;
  text-align   : center;
  max-width    : 250px;
  max-height   : 315px;
  padding      : 4px;
  font-family  : "Nunito Sans";
  font-size    : 12px;
  font-style   : normal;
  line-height  : normal;
  z-index      : 10000;
  border-radius: 4px;
  border       : 1px solid var(--Fonts-Link, #005FA4);
  background   : #FFF;
  overflow     : auto;
  box-shadow   : 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 8px 10px -5px rgba(0, 0, 0, 0.20);
}

.pw-graph-tooltip .tooltip-content {
  padding-top   : 5px;
  padding-bottom: 5px;
  padding-right : 5px;
  padding-left  : 5px;
  display       : flex;
  max-width     : 103px;
  max-height    : 90px;
  flex-direction: column;
  align-items   : flex-start;
  font-family   : "Nunito Sans";
  overflow      : auto;
  font-size     : 12px;
  // line-height: 0px;
  line-height   : 0px;
}

.pw-graph-tooltip .tooltip-m-content {
  padding-top   : 5px;
  padding-bottom: 5px;
  padding-right : 18px;
  padding-left  : 5px;
  display       : flex;
  //width: calc(100% - 23px); 
  max-height    : 305px;
  flex-direction: column;
  align-items   : flex-start;
  font-family   : "Nunito Sans";
  font-size     : 12px;
  line-height   : normal;
  overflow      : auto;
  max-width     : 103px;
}

.pw-graph-tooltip .tooltip-link {
  display      : flex;
  font-family  : "Nunito Sans";
  font-style   : normal;
  font-weight  : 400;
  font-size    : 12px;
  line-height  : 5px;
  color        : #005FA4;
  cursor       : pointer;
  max-width    : 103px;
  white-space  : nowrap;
  text-overflow: ellipsis;
  line-height  : 5px;
}

.pw-graph-tooltip ::-webkit-scrollbar {
  padding-left: 15px;
  width       : 4px;
  height      : 4px;
}

.pw-graph-tooltip ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width        : 4px;
  background   : #d9d9d9;
}

.pw-graph-tooltip ::-webkit-scrollbar-track {
  box-shadow   : unset !important;
  border-radius: 10px;
}

div.avg-tooltip {
  color         : white;
  position      : absolute;
  text-align    : center;
  max-height    : 28px;
  padding       : 2px;
  font-family   : Nunito Sans;
  font-size     : 12px;
  font-style    : normal;
  font-weight   : 600;
  line-height   : normal;
  border        : 0px;
  pointer-events: none;
  z-index       : 10000;
  border-radius : 2px;
  background    : #616161;
  min-width     : 68px;
  // box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.20), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.tip {
  width           : 3rem;
  background-color: #eee;
  text-align      : center;
  // box-shadow: 0px 2px 10px -2px #aaa;
  border          : 2px solid #444;
  transition      : all 160ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.tip::after {
  content     : " ";
  position    : absolute;
  bottom      : -.5rem;
  left        : 50%;
  margin-left : -.5rem;
  border-style: solid;
  border-width: .5rem .5rem 0 .5rem;
  border-color: #444 transparent transparent transparent;
}

.inventory-container {
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #005FA4 !important;
  }

  .mat-form-field-appearance-outline:hover .mat-form-field-outline-thick {
    color: #757575 !important; // Old val: #739d44;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
  .mat-form-field.mat-focused .mat-form-field-label {
    color: black !important;
  }
}

.demand-planning-ref-overlay {
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #005FA4 !important;
  }

  .mat-form-field-appearance-outline:hover .mat-form-field-outline-thick {
    color: #757575 !important; // Old val: #739d44;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
  .mat-form-field.mat-focused .mat-form-field-label {
    color: black !important;
  }
}


.mat-tooltip {
  margin: 5px 14px 0 14px !important;
}

.currunt-week-legend {
  visibility: hidden;
}

.currunt-week-legend:last-child {
  visibility: visible;
}

.axis-labels {
  color     : #757575;
  text-align: center;

  /* Body/B1_Semibold */
  font-family: "Nunito Sans";
  font-size  : 16px;
  font-style : normal;
  font-weight: 600;
  line-height: normal;
}

.current-week-lbl {
  color: #757575;

  /* Body/B3 */
  font-family: "Nunito Sans";
  font-size  : 12px;
  font-style : normal;
  font-weight: 400;
  line-height: normal;
}

.axis-value {
  color     : #424242;
  text-align: right;

  /* Body/B2_Semibold */
  font-family : "Nunito Sans";
  // font-size: 14px;
  font-style  : normal;
  font-weight : 600;
  line-height : normal;
}


.price-alert-crnt-yr-chart {
  width: 95%;
}

.price-alert-old-yr-chart {
  width: 95%;
}

.price-alert-kendo-popup {
  z-index   : 100002 !important;
  box-shadow: none !important;
}

.custome-k-popover {
  border-color: #114267 !important;
}



.tooltip-class {
  .k-callout {
    color: #D50000 !important;
  }
}

.tooltip-class * {
  color     : white;
  background: #D50000;

  .k-callout {
    color     : #D50000 !important;
    background: transparent;
  }
}

.demand-planning-ovelay {
  .mat-dialog-container {
    overflow: hidden;
  }
}

.custom-pw-k-popover {
  margin-top   : 15px !important;
  margin-bottom: 15px !important;
  margin-left  : -1px !important;

  ::-webkit-scrollbar {
    width : 6px !important;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    // background: rgba(224, 224, 224, 1) !important;;
    border-radius: 5px;
    width        : 6px !important;
    background   : #d9d9d9;
  }

  ::-webkit-scrollbar-track {
    box-shadow   : unset !important;
    border-radius: 10px;
  }

  .k-popover-body {
    padding: 12px 12px;
  }

  z-index: 111 !important;

  .detailed-info {
    // width: 494px;
    padding       : 0px;
    display       : flex;
    flex-direction: column;
    gap           : 20px;
    overflow      : auto;
    padding-right : 4px;
    max-height    : 427px;


    ::-webkit-scrollbar {
      width : 6px !important;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      width        : 6px !important;
      background   : #d9d9d9;
    }

    ::-webkit-scrollbar-track {
      box-shadow   : unset !important;
      border-radius: 10px;
    }

    .order-details {
      display       : flex;
      gap           : 10px;
      flex-direction: column;

      .del-label {
        color      : var(--Fonts-Secondary, var(--Fonts-Secondary, #424242));
        font-family: "Nunito Sans";
        font-size  : 16px;
        font-style : normal;
        font-weight: 400;
        line-height: normal;
      }

      .ord-det-container {
        display       : flex;
        flex-direction: column;
        font-size     : 12px;
        padding       : 10px;
        background    : #F5F5F5;
        border-radius : 4px;

        .ord-label {
          color         : var(--Fonts-Link, var(--Fonts-Link, #005FA4));
          /* Body/B1_Semibold */
          font-family   : "Nunito Sans";
          font-size     : 16px;
          font-style    : normal;
          font-weight   : 600;
          line-height   : normal;
          padding-bottom: 10px;
          cursor        : pointer;
        }

        .order-details-body {
          display       : flex;
          flex-direction: row;
          font-size     : 12px;

          .order-details-section {
            display       : flex;
            flex-direction: column;
            flex-basis    : 50%;
            gap           : 8px;

            .info {
              display       : flex;
              flex-direction: row;
              align-items   : baseline;

              .label {
                color                : var(--Fonts-Tertiary, var(--Fonts-Teritary, #757575));
                font-feature-settings: 'clig'off, 'liga'off;
                font-family          : "Nunito Sans";
                font-size            : 14px;
                font-style           : normal;
                font-weight          : 400;
                line-height          : normal;
                padding-right        : 8px;
                min-width            : 98px !important;
              }

              .value {
                color      : var(--Fonts-Primary, var(--Fonts-Primary, #212121));
                font-family: "Nunito Sans";
                font-size  : 14px;
                font-style : normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }

          .order-details-section-2 {
            border-left : 1px solid #e1dbdb;
            padding-left: 15px;
          }
        }
      }
    }
  }

  .loading-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    min-height     : 150px;
    font-family    : "Nunito Sans";
    font-size      : 16px;
    font-style     : normal;
    font-weight    : 400;
    line-height    : normal;
  }
}

.price-alert-custom-kendo-popup {
  box-shadow: none !important;
}

.global-search-k-popover {
  border-color: #6BA1F3 !important;
  // width       : 210vh !important;
  height      : 88vh !important;
}

//125%
@include responsive(desktop) {
  .mat-primary .mat-pseudo-checkbox-checked,
  .mat-primary .mat-pseudo-checkbox-indeterminate {
    background-color: #114267 !important;
  }
  [class ^="cdk-overlay-pane"] {
    transform: translateX(-16px) !important;

    &.mat-datepicker-popup {
      transform: translateX(0px) !important;
    }

    .mat-menu-panel {
      margin: 1px 0px 0px 16px !important;

    }

    .mat-autocomplete-panel {
      margin   : 1px 0px 0px 16px !important;
      min-width: 16vw !important;
    }
  }
    .ord-custom-select-panel-wrap {

    position: relative !important;
    top     : 25px !important;

    .mat-select-panel {

      margin-top : unset !important;
      margin-left: 8px !important;
      min-width  : 100% !important;
      width      : auto !important;
    }

    .mat-option {
      .mat-option-text {
        font-family: $Nunito_Sans !important;
        font-style : normal !important;
        font-weight: 400 !important;
        font-size  : $font_12PX !important;
        line-height: 19px !important;
        color      : #757575 !important;
      }
    }

    .mat-option:hover {
      background: #EAF5DC !important;
    }

    // .mat-select-search-panel {
    //    min-width  : unset !important;
    // }

    .mat-selected {
      background: #EAF5DC !important;

      .mat-option-text {
        background    : #EAF5DC !important;
        color         : #212121 !important;
        font-size     : $font_12PX !important;
        font-style    : normal !important;
        font-weight   : 600 !important;
        line-height   : normal !important;
        letter-spacing: 0.5px !important;
      }

    }

    .clear-selected {
      .mat-pseudo-checkbox {
        display: none;
      }

      .mat-option-text {
        color: #fc0b0b !important;
      }
    }

    .mat-select-search-input {
      font-family: $Nunito_Sans !important;
      font-style : normal !important;
      font-weight: 400 !important;
      font-size  : $font_12PX !important;
      line-height: 19px !important;
      color      : #757575 !important;
    }

    ::-webkit-scrollbar-thumb {
      // background: rgba(224, 224, 224, 1) !important;;
      border-radius: 5px;
      width        : 6px !important;
      background   : #d9d9d9;
    }

    ::-webkit-scrollbar-track {
      box-shadow   : unset !important;
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width : 6px !important;
      height: 10px;
    }
  }

  .global-search-k-popover-body{
    padding: 10px !important;
  }
}

//100%
@include responsive(large-desktop) {
   .mat-primary .mat-pseudo-checkbox-checked,
   .mat-primary .mat-pseudo-checkbox-indeterminate {
    background-color: #114267 !important;
  }
  [class ^="cdk-overlay-pane"] {
    //position : relative !important;
    //top      : 27px !important;
    
  
    transform : translateX(-16px) !important;

    .mat-menu-panel {
      margin: 1px 0px 0px 16px !important;

    }

    .mat-autocomplete-panel {
      margin   : 1px 0px 0px 16px !important;
      min-width: 14vw !important;
    }

    &.mat-datepicker-popup {
      transform: translateX(0px) !important;
    }
  }
  .ord-custom-select-panel-wrap {
    position: relative !important;
    top     : 25px !important;

    .mat-select-panel {
      margin-top : unset !important;
      margin-left: 8px !important;
      min-width  : 100% !important;
      width      : auto !important;
    }

    .mat-option {
      .mat-option-text {
        font-family: "Nunito Sans" !important;
        font-style : normal !important;
        font-weight: 400 !important;
        font-size  : 14px !important;
        line-height: 19px !important;
        color      : #757575 !important;
      }
    }

    .mat-option:hover {
      background: #EAF5DC !important;
    }

    // .mat-select-search-panel {
    //   min-width: calc(100% - 52px) !important;
    // }

    .mat-selected {
      background: #EAF5DC !important;

      .mat-option-text {
        background    : #EAF5DC !important;
        color         : #212121 !important;
        font-size     : 14px !important;
        font-style    : normal !important;
        font-weight   : 600 !important;
        line-height   : normal !important;
        letter-spacing: 0.5px !important;
      }
    }

    .clear-selected {
      .mat-pseudo-checkbox {
        display: none;
      }

      .mat-option-text {
        color: #fc0b0b !important;
      }
    }

    .mat-select-search-input {
      font-family: "Nunito Sans" !important;
      font-style : normal !important;
      font-weight: 400 !important;
      font-size  : 13px !important;
      line-height: 19px !important;
      color      : #757575 !important;
    }



    ::-webkit-scrollbar-thumb {
      // background: rgba(224, 224, 224, 1) !important;;
      border-radius: 5px;
      width        : 6px !important;
      background   : #d9d9d9;
    }

    ::-webkit-scrollbar-track {
      box-shadow   : unset !important;
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width : 6px !important;
      height: 10px;
    }
  }
  
  .global-search-k-popover-body{
    padding: 12px !important;
  }
}
.ol-filter-mat-select{
    min-width: calc(100% + 47px) !important;
}

.procurant-select-panel-class{
  font-family: "Nunito Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #757575 !important;
  .mat-select-min-line{
    font-size: 12px !important;
  }
}

.complianceTable{
  .k-grid th {
    background: #045a66;
    color: white;
  }
}

.x-axix-text{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-tooltip-trace{
  margin-top: 0px !important;
  margin-left: -4px !important;
}

.complianceTable {
  kendo-grid {
      .k-pager-wrap  {
          background-color: #FFFFFF;
          border-color    : transparent;
      
          .k-pager-sizes .k-input-inner,
          .k-pager-sizes .k-input-value-text {
              text-overflow: clip;
              font-family  : 'Nunito Sans';
              font-style   : normal;
              font-weight  : 400;
              font-size    : 16px;
              line-height  : 22px;
              color        : #757575;
          }
      
          .k-picker-solid {
              color           : #A1A1A1;
              background-color: transparent;
              box-sizing      : border-box !important;
              border          : 1px solid #A1A1A1;
              border-radius   : 2px;
          }
      
          kendo-label>.k-label {
              font-family: 'Nunito Sans';
              font-style : normal;
              font-weight: 400;
              font-size  : 16px;
              line-height: 22px;
              color      : #A2A2A2;
          }
      
          .k-pager-info {
              text-align     : left;
              justify-content: flex-start;
              order          : 9;
              font-family    : 'Nunito Sans';
              font-style     : normal;
              font-weight    : 400;
              font-size      : 16px;
              line-height    : 22px;
              color          : #A2A2A2;
          }
      
          .k-pager-numbers .k-link.k-selected {
              // color           : #FFFFFF;
              // background-color: #114267;
              color           : #114267 !important;
              background-color: #EAF5DC !important;
              border-radius   : 20px;
          }
      
          .k-pager-numbers .k-link {
              color: #114267 !important;
      
              &:hover {
                  background-color: rgb(17 66 103 / 13%) !important;
              }
          }
      }
  }
  
}